// ExtraInformationSection.tsx

import React from 'react';
import { Flight } from '../../../types';
import { Subheading } from '../../../catalystui/heading';

interface ExtraInformationSectionProps {
    formValues: Flight;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const ExtraInformationSection: React.FC<ExtraInformationSectionProps> = ({
                                                                             formValues,
                                                                             handleChange,
                                                                         }) => {
    return (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-4">
            <div className="px-4 sm:px-0">
                <Subheading>Extra information</Subheading>
            </div>
            <div
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                <div className="px-4 py-4 sm:px-8 sm:py-8">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                            <label htmlFor="comment"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Remarks
                            </label>
                            <div className="mt-2 ">
        <textarea
            rows={3}
            name="remarks"
            id="remarks"
            value={formValues.remarks}
            onChange={handleChange}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-zinc-900 dark:ring-zinc-600 dark:text-gray-100"
        />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ExtraInformationSection;
