import React, {Fragment, useState, useEffect} from "react";
import {Menu, Transition} from '@headlessui/react';
import {EllipsisHorizontalIcon, PlusCircleIcon, CheckCircleIcon} from '@heroicons/react/20/solid';
import apiClient from "../../../../tools/AxiosInterceptor";
import {v4 as uuidv4} from 'uuid';
import {Heading} from "../../../../catalystui/heading";
import {Button} from "../../../../catalystui/button";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from "../../../../catalystui/dialog";
import {Field, FieldGroup, Fieldset, Label} from "../../../../catalystui/fieldset";
import {Input} from "../../../../catalystui/input";
import {Select} from "../../../../catalystui/select";
import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../../../../catalystui/dropdown";
import {classNames} from "../../../../tools/utils";
import {useParams} from "react-router-dom";
import {Issue} from "../../../../types"

const statuses = {
    New: 'text-green-700 bg-green-50 dark:text-green-100 dark:bg-green-800 ring-green-600/20',
    Review: 'text-yellow-700 bg-yellow-50 dark:text-yellow-100 dark:bg-yellow-800 ring-yellow-600/20',
    Open: 'text-yellow-700 bg-yellow-50 dark:text-yellow-100 dark:bg-yellow-800 ring-yellow-600/20',
    Closed: 'text-gray-600 bg-gray-50 dark:text-gray-100 dark:bg-gray-800 ring-gray-600/20',
    Hold: 'text-gray-600 bg-gray-50 dark:text-gray-100 dark:bg-gray-800 ring-gray-600/20',
    Updated: 'text-red-700 bg-red-50 dark:text-red-100 dark:bg-red-800 ring-red-600/20',
};

const priorities = {
    Low: 'text-green-700 bg-green-50 dark:text-green-100 dark:bg-green-800 ring-green-600/20',
    Medium: 'text-yellow-700 bg-yellow-50 dark:text-yellow-100 dark:bg-yellow-800 ring-yellow-600/20',
    High: 'text-red-500 bg-red-50 dark:text-red-100 dark:bg-red-800 ring-red-400/20',
    Critical: 'text-red-700 bg-red-50 dark:text-red-100 dark:bg-red-800 ring-red-600/10',
};

type Priority = keyof typeof priorities;
type Status = keyof typeof statuses;
type EditIssueFormProps = {
    issue?: Issue;
    onSave: (issue: Issue) => void;
    onClose: () => void;
};

const priorityWeights = {
    "Critical": 1,
    "High": 2,
    "Medium": 3,
    "Low": 4,
    "Unknown": 5
};

const AircraftIssueSection = () => {
    const {uuid: aircraftUuid} = useParams<{ uuid: string }>();
    const [error, setError] = useState<string | null>(null);
    const [issuesData, setIssuesData] = useState<Issue[]>([]);
    const [editingIssue, setEditingIssue] = useState<Issue | null>(null);
    const [addingNewIssue, setAddingNewIssue] = useState(false);
    const [notification, setNotification] = useState("");

    const sortIssuesByPriority = (issues: Issue[]) => {
        return issues.sort((a, b) => {
            return priorityWeights[a.priority] - priorityWeights[b.priority];
        });
    };

    useEffect(() => {
        // Explicitly declare timeoutId as a NodeJS.Timeout or number
        let timeoutId: NodeJS.Timeout | null = null;

        if (notification) {
            timeoutId = setTimeout(() => {
                setNotification("");
            }, 3000);
        }

        // Cleanup function to clear the timeout
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [notification]);


    useEffect(() => {
        // Assuming convertToIssues is a function that formats your data
        fetchIssues();
    }, []);

    const fetchIssues = async () => {
        try {
            const response = await apiClient.get(`/v1/getAircraftIssues?aircraftUuid=${aircraftUuid}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
            const sortedIssues = sortIssuesByPriority(response.data);
            console.log(response.data)
            setIssuesData(sortedIssues);
        } catch (err: any) {
            setError(err.message || "Unable to fetch aircraft issues.");
        }
    };

    const handleSaveEditedIssue = async (updatedIssue: Issue) => {
        try {
            const response = await apiClient.put('/v1/updateAircraftIssue', updatedIssue, {
                headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
            } as any);
            fetchIssues(); // Refresh the issues list after update
            setNotification("Aircraft issue edited successfully."); // Set success message
            setEditingIssue(null); // Hide the form
        } catch (err: any) {
            setError(err.message || "Unable to update aircraft issue.");
        }
    };

    const handleAddNewIssue = async (newIssue: Issue) => {
        try {
            const response = await apiClient.post('/v1/addAircraftIssue', newIssue, {
                headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
            });
            fetchIssues(); // Refresh the issues list after adding
            setAddingNewIssue(false); // Hide the form
            setNotification("New aircraft issue added successfully."); // Set success message
        } catch (err: any) {
            setError(err.message || "Unable to add new aircraft issue.");
            setNotification(""); // Clear notification on error
        }
    };


    const handleDeleteIssue = async (issueId: string) => {
        if (window.confirm('Are you sure you want to delete this issue?')) {
            try {
                const response = await apiClient.delete(`/v1/deleteAircraftIssue/${issueId}`, {
                    headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
                } as any);
                fetchIssues(); // Refresh the issues list after deletion
                setNotification("Aircraft issue deleted successfully."); // Set success message
            } catch (err: any) {
                setError(err.message || "Unable to delete aircraft issue.");
            }
        }
    };

    const handleEdit = (issueId: string) => {
        const issueToEdit = issuesData.find(issue => issue.uuid === issueId);
        if (issueToEdit) {
            setEditingIssue(issueToEdit);
            // Additional logic to open a modal or navigate to the edit form
        }
    };

    const handleAddNewIssueClick = () => {
        setAddingNewIssue(true);
    };

    const EditIssueForm: React.FC<EditIssueFormProps> = ({issue, onSave, onClose}) => {

        const initialState: Issue = issue || {
            uuid: uuidv4(),
            issue: '',
            priority: 'Low', // TypeScript now understands this as 'Critical' | 'High' | 'Medium' | 'Low' | 'Unknown'
            cycleType: '',
            threshold: 0,
            nextDueDate: '',
            lastPerformedDate: '',
            details: '',
            status: 'New',
            nextDueHours: 0,
            currentHours: 0,
            lastDoneHours: 0,
            actionNeeded: '',
            estimatedLaborCost: '',
            estimatedPartsCost: '',
            partsNeeded: '',
            aircraftUuid: aircraftUuid || ''
        };
        const [issueToEdit, setIssueToEdit] = useState<Issue>(initialState);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            const {name, value} = e.target;
            setIssueToEdit(prevIssue => ({
                ...prevIssue,
                [name]: value
            }));
        };

        const handleSubmit = (e?: React.FormEvent) => {
            if (e) e.preventDefault();
            onSave(issueToEdit); // Calls the onSave callback with the updated issue data
        };

        const isEditMode = issue != null;

        return (
            <Dialog open={true} onClose={onClose} size="5xl">
                <DialogTitle>{isEditMode ? `Editing ${issue.issue}` : 'Add New Issue'}</DialogTitle>
                <DialogDescription>
                    Fill out the form to {isEditMode ? 'edit this' : 'add a new'} issue.
                </DialogDescription>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <Field>
                                    <Label htmlFor="issue">Issue</Label>
                                    <Input
                                        type="text"
                                        name="issue"
                                        id="issue"
                                        value={issueToEdit.issue}
                                        onChange={handleChange}
                                        placeholder="Enter issue description"
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="details"
                                           className="block text-sm font-medium leading-6 text-gray-900">Details</Label>
                                    <Input
                                        type="text"
                                        name="details"
                                        id="details"
                                        value={issueToEdit.details}
                                        onChange={handleChange}
                                        placeholder="Enter issue details"
                                    />
                                </Field>
                            </div>
                        </FieldGroup>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <Field>
                                    <Label htmlFor="priority">Priority</Label>
                                    <Select
                                        id="priority"
                                        name="priority"
                                        value={issueToEdit.priority}
                                        onChange={handleChange}
                                    >
                                        <option>Unknown</option>
                                        <option>Low</option>
                                        <option>Medium</option>
                                        <option>High</option>
                                        <option>Critical</option>
                                    </Select>
                                </Field>
                                <Field>

                                    <Label htmlFor="status">
                                        Status
                                    </Label>
                                    <Select
                                        id="status"
                                        name="status"
                                        value={issueToEdit.status}
                                        onChange={handleChange}
                                        defaultValue="New">
                                        <option>New</option>
                                        <option>Review</option>
                                        <option>Open</option>
                                        <option>Closed</option>
                                        <option>Hold</option>
                                        <option>Updated</option>
                                    </Select>
                                </Field>
                            </div>
                        </FieldGroup>
                        <FieldGroup>
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">

                                <Field>
                                    <Label htmlFor="estimatedLaborCost">
                                        Labor Cost Estimate (net, EUR)
                                    </Label>
                                    <Input
                                        type="number"
                                        name="estimatedLaborCost"
                                        id="estimatedLaborCost"
                                        value={issueToEdit.estimatedLaborCost}
                                        onChange={handleChange}
                                        placeholder="Price for labor"
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="estimatedPartsCost">
                                        Parts Cost Estimate (net, EUR)
                                    </Label>
                                    <Input
                                        type="number"
                                        name="estimatedPartsCost"
                                        id="estimatedPartsCost"
                                        value={issueToEdit.estimatedPartsCost}
                                        onChange={handleChange}
                                        placeholder="Price for parts"
                                    />
                                </Field>
                                <Field>
                                    <Label htmlFor="partsNeeded">
                                        Needed parts
                                    </Label>
                                    <Input
                                        type="text"
                                        name="partsNeeded"
                                        id="partsNeeded"
                                        value={issueToEdit.partsNeeded}
                                        onChange={handleChange}
                                        placeholder="What parts are needed?"
                                    />
                                </Field>
                            </div>
                            <Field>
                                <Label htmlFor="actionNeeded">
                                    Action Needed
                                </Label>
                                <Input
                                    type="text"
                                    name="actionNeeded"
                                    id="actionNeeded"
                                    value={issueToEdit.actionNeeded}
                                    onChange={handleChange}
                                    placeholder="Who needs to do what?"
                                />
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button outline onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Save</Button>
                </DialogActions>
            </Dialog>
        )
            ;
    };

    return (
        <div>
            {notification && <div className="mt-4 rounded-md bg-green-50 dark:bg-green-900 p-4 my-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400 dark:text-green-400" aria-hidden="true"/>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800 dark:text-green-200">{notification}</h3>
                    </div>
                </div>
            </div>}
            {editingIssue && (
                <EditIssueForm
                    issue={editingIssue}
                    onSave={handleSaveEditedIssue} // Pass handleSaveEditedIssue directly
                    onClose={() => setEditingIssue(null)}
                />
            )}
            {addingNewIssue && (
                <EditIssueForm
                    onSave={handleAddNewIssue}
                    onClose={() => setAddingNewIssue(false)}
                />
            )}
            <div>
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>
                            Issues
                        </Heading>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="sm:ml-3">
          <Button
              onClick={handleAddNewIssueClick}
          >
            <PlusCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
            Add Issue
          </Button>
        </span>
                    </div>
                </div>
                <div>
                    <ul role="list" className="grid grid-cols-1 py-8 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                        {issuesData && issuesData.map((issue) => {
                            return (
                                <li key={issue.uuid}
                                    className="overflow-hidden rounded-xl border border-gray-200 dark:border-zinc-700">
                                    <div
                                        className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 dark:bg-zinc-800 p-3">
                                        <div
                                            className="text-sm font-medium leading-4 text-gray-900 dark:text-gray-100">{issue.issue}</div>
                                        <div className="ml-auto">
                                            <Dropdown>
                                                <DropdownButton plain aria-label="More options"
                                                                className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500 dark:text-gray-100">
                                                    <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                                                </DropdownButton>
                                                <DropdownMenu anchor="bottom end"
                                                              className="w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <DropdownItem
                                                        onClick={() => handleEdit(issue.uuid)}>Edit</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => handleDeleteIssue(issue.uuid)}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6 dark:divide-zinc-700">
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Priority</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className={classNames(
                                                    priorities[issue.priority as Priority] ?? statuses.Hold,
                                                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}>{issue.priority}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Status</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className={classNames(
                                                    statuses[issue.status as Status] ?? statuses.Hold,
                                                    'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'
                                                )}>{issue.status}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Details</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900 dark:text-gray-200">{issue.details}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Action needed</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className="text-gray-900 dark:text-gray-200">{issue.actionNeeded}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Labor cost estimate</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className="text-gray-900 dark:text-gray-200">{issue.estimatedLaborCost}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Parts cost estimate</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div
                                                    className="text-gray-900 dark:text-gray-200">{issue.estimatedPartsCost}</div>
                                            </dd>
                                        </div>
                                        <div className="flex justify-between gap-x-4 py-1">
                                            <dt className="text-gray-500 dark:text-gray-200">Required parts</dt>
                                            <dd className="flex items-start gap-x-2">
                                                <div className="text-gray-900">{issue.partsNeeded}</div>
                                            </dd>
                                        </div>
                                    </dl>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>


        </div>

    )
}

export default AircraftIssueSection