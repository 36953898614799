import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import apiClient from "../../tools/AxiosInterceptor";
import {XCircleIcon} from '@heroicons/react/20/solid'
import {Heading} from "../../catalystui/heading";
import {Field, FieldGroup, Fieldset, Label} from "../../catalystui/fieldset";
import {Input} from "../../catalystui/input";
import {Button} from "../../catalystui/button";
import {Badge} from "../../catalystui/badge";

interface LoginResponse {
    token: string;
    role: string;
    uuid: string;
    email: string;
    organizationIds: string
}

const LoginScreen = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the location object
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    // Get the 'from' state, default to "/dashboard/flights" if not present
    const from = (location.state as { from?: Location })?.from?.pathname || "/dashboard/flights";
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage(null);

        const formData = new FormData(e.currentTarget);
        const data = {
            login: formData.get("login") as string,
            password: formData.get("password") as string
        };

        try {
            const response = await apiClient.post<LoginResponse>("/login", data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            } as any);

            if (response.status === 200 && response.data) {
                console.log(response.data)
                sessionStorage.setItem('token', response.data.token);       // Store the token
                sessionStorage.setItem('user_role', response.data.role);    // Store the user's role
                sessionStorage.setItem('user_uuid', response.data.uuid);    // Store the user's UUID
                sessionStorage.setItem('email', response.data.email);       // Store the user's email
                sessionStorage.setItem('organizationIds', JSON.stringify(response.data.organizationIds)); // Ensure valid JSON
                navigate(from);
            } else {

                console.log(response.data);
            }
        } catch (error) {
            setErrorMessage("Invalid login or password.");
            console.error("Error during the login process", error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <Heading className="mt-10 leading-9 tracking-tight">Sign in to your account</Heading>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <FieldGroup>
                        <Field>
                            <Label>Login</Label>
                            <Input id="login"
                                   name="login"
                                   type="text"
                                   autoComplete="login"
                                   required/>
                        </Field>
                        <Field>
                            <Label>Password</Label>
                            <Input id="password"
                                   name="password"
                                   type="password"
                                   autoComplete="current-password"
                                   required/>
                        </Field>
                        {errorMessage && (
                            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                <div className="rounded-md bg-red-50 p-2">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-red-800">{errorMessage}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <Button type="submit" className="w-full">Sign in</Button>
                    </FieldGroup>

                </form>
            </div>
        </div>
    )
};

export default LoginScreen;