import React, {useEffect, useState} from "react";
import apiClient from "../../tools/AxiosInterceptor";
import {
    PlusCircleIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    FunnelIcon,
} from "@heroicons/react/20/solid";
import {useNavigate} from "react-router-dom";
import {Flight, User} from "../../types";
import * as XLSX from "xlsx";
import {Heading, Subheading} from "../../catalystui/heading";
import {Button} from "../../catalystui/button";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from "../../catalystui/dropdown";
import {
    FieldGroup,
    Fieldset,
    Field,
    Label,
} from "../../catalystui/fieldset";
import {Input} from "../../catalystui/input";
import {Table, TableHead, TableRow, TableHeader, TableBody, TableCell} from "../../catalystui/table";

interface UsersResponse {
    users: User[];
}

interface FlightsByMonth {
    monthYear: string;
    flights: Flight[];
}

const FlightManagementSection: React.FC = () => {
    const [flights, setFlights] = useState<Flight[]>([]);
    const [filteredFlights, setFilteredFlights] = useState<Flight[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [expandedMonth, setExpandedMonth] = useState<string | null>(null);
    const [startDateTime, setStartDateTime] = useState<string>("");
    const [endDateTime, setEndDateTime] = useState<string>("");
    const [pilots, setPilots] = useState<User[]>([]);
    const [selectedPilot, setSelectedPilot] = useState<User | null>(null);
    const [remarksQuery, setRemarksQuery] = useState<string>("");
    const [showFilters, setShowFilters] = useState(false);
    const [pilotQuery, setPilotQuery] = useState<string>("");
    const [currentOrganizationId, setCurrentOrganizationId] = useState<string | null>(null);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const navigate = useNavigate();

    // Polling parameters
    const pollingInterval = 50; // 50 ms
    const maxPollingAttempts = 10;

    // Fetch current organization with polling
    useEffect(() => {
        let attempts = 0;
        const interval = setInterval(() => {
            const storedOrg = sessionStorage.getItem("selectedOrg");
            if (storedOrg) {
                try {
                    const parsedOrg = JSON.parse(storedOrg);
                    if (parsedOrg && parsedOrg.id) {
                        setCurrentOrganizationId(parsedOrg.id);
                        console.log("Organization ID found:", parsedOrg.id);
                        clearInterval(interval);
                        setIsInitialized(true);
                    } else {
                        console.error("Parsed organization does not have an 'id' field.");
                    }
                } catch (e) {
                    console.error("Failed to parse 'selectedOrg' from sessionStorage:", e);
                }
            }
            attempts += 1;
            if (attempts >= maxPollingAttempts) {
                console.warn("Max polling attempts reached. Organization not found.");
                clearInterval(interval);
                setIsInitialized(true);
            }
        }, pollingInterval);

        // Cleanup on unmount
        return () => clearInterval(interval);
    }, []);

    // Fetch flights and pilots when organization changes
    useEffect(() => {
        if (currentOrganizationId) {
            fetchFlights();
            fetchPilots();
        }
    }, [currentOrganizationId]);

    // Apply filters when relevant state changes
    useEffect(() => {
        applyFilter();
    }, [startDateTime, endDateTime, selectedPilot, remarksQuery, flights]);

    const handleAddFlight = () => {
        navigate("/dashboard/flights/add");
    };

    const fetchFlights = async () => {
        try {
            const response = await apiClient.get("/getFlights", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });

            const allFlights: Flight[] = response.data;

            // Filter flights by the current organization ID
            const orgFlights = allFlights.filter(
                (flight) => flight.organizationId === currentOrganizationId
            );

            setFlights(orgFlights);
            setFilteredFlights(orgFlights);

            const groupedFlights = groupFlightsByMonth(orgFlights);
            setExpandedMonth(findMostRecentMonthWithFlights(groupedFlights));

            setError(null); // Clear any previous errors
            console.log("Fetched flights:", orgFlights.length);
        } catch (err: any) {
            setFlights([]);
            setFilteredFlights([]);
            setError(err.message || "Unable to fetch flights.");
            console.error("Error fetching flights:", err);
        }
    };

    const fetchPilots = async () => {
        try {
            const response = await apiClient.get<UsersResponse>("/getUsers", {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            });
            setPilots(response.data.users);
            console.log("Fetched pilots:", response.data.users.length);
        } catch (err: unknown) {
            if (err instanceof Error) {
                setError(err.message);
                console.error("Error fetching pilots:", err.message);
            } else {
                setError("An unknown error occurred");
                console.error("Unknown error fetching pilots:", err);
            }
        }
    };

    const applyFilter = () => {
        const filtered = flights.filter((flight) => {
            const flightDateTime = new Date(
                `${flight.flight_date.split("T")[0]}T${flight.departure_time}`
            );
            const startDate = startDateTime ? new Date(startDateTime) : null;
            const endDate = endDateTime ? new Date(endDateTime) : null;

            const pilotFullName = selectedPilot
                ? `${selectedPilot.first_name.toLowerCase()} ${selectedPilot.last_name.toLowerCase()}`
                : null;

            const matchPilot =
                selectedPilot === null ||
                flight.pilot_in_command.toLowerCase() === pilotFullName;

            const matchDate =
                (!startDate || flightDateTime >= startDate) &&
                (!endDate || flightDateTime <= endDate);

            const matchRemarks =
                remarksQuery === "" ||
                (flight.remarks &&
                    flight.remarks.toLowerCase().includes(remarksQuery.toLowerCase()));

            return matchDate && matchPilot && matchRemarks;
        });

        const sortedFiltered = filtered.sort((a, b) => {
            const dateA = new Date(`${a.flight_date}T${a.departure_time}`);
            const dateB = new Date(`${b.flight_date}T${b.departure_time}`);
            return dateA.getTime() - dateB.getTime();
        });

        setFilteredFlights(sortedFiltered);
        console.log("Filtered flights:", sortedFiltered.length);
    };

    const handleStartDateChange = (value: string) => {
        setStartDateTime(value);
    };

    const handleEndDateChange = (value: string) => {
        setEndDateTime(value);
    };

    const handleEditFlight = (uuid: string) => {
        navigate(`/dashboard/flights/edit/${uuid}`);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("pl-PL");
    };

    const handleExportToExcel = () => {
        const excelData = filteredFlights.map((flight) => ({
            Samolot: flight.aircraft_registration,
            Data: formatDate(flight.flight_date),
            Start: flight.departure_airfield,
            Lądowanie: flight.arrival_airfield,
            Pilot: flight.pilot_in_command,
            "Numer licencji": flight.license_number,
            "Czas startu": flight.departure_time,
            "Czas lądowania": flight.arrival_time,
            "Czas lotu": flight.flight_time,
            "Czas na ziemi": flight.ground_time,
            "Liczba lądowań": flight.number_of_landings,
            "MTH przed lotem": flight.engine_time_before_flight,
            "MTH po locie": flight.engine_time_after_flight,
            "Różnica MTH": flight.engine_time_difference,
            "Stan oleju podczas inspekcji": flight.oil_on_inspection,
            "Olej dodany": flight.oil_added,
            "Stan oleju do odlotu": flight.oil_on_departure,
            "Stan paliwa podczas inspekcji": flight.fuel_on_inspection,
            "Dodane paliwo": flight.fuel_added,
            "Stan paliwa do odlotu": flight.fuel_on_departure,
            "Stan paliwa po lądowaniu": flight.fuel_on_landing,
            "Zużyte paliwo": flight.fuel_used,
            Uwagi: flight.remarks || "",
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Loty");

        XLSX.writeFile(workbook, "loty_przefiltrowane.xlsx");
        console.log("Exported flights to Excel.");
    };

    const groupFlightsByMonth = (flights: Flight[] = []): FlightsByMonth[] => {
        if (!Array.isArray(flights)) {
            flights = [];
        }

        const groupsMap = new Map<string, Flight[]>();

        flights.forEach((flight) => {
            const date = new Date(flight.flight_date);
            const monthYear = date.toLocaleString("default", {
                month: "long",
                year: "numeric",
            });

            if (!groupsMap.has(monthYear)) {
                groupsMap.set(monthYear, []);
            }
            groupsMap.get(monthYear)!.push(flight);
        });

        // Convert Map to array and sort months descending
        const groupsArray: FlightsByMonth[] = Array.from(groupsMap, ([monthYear, flights]) => ({
            monthYear,
            flights,
        }));

        groupsArray.sort((a, b) => {
            const dateA = new Date(a.monthYear);
            const dateB = new Date(b.monthYear);
            return dateB.getTime() - dateA.getTime();
        });

        return groupsArray;
    };

    const findMostRecentMonthWithFlights = (groupedFlights: FlightsByMonth[]): string | null => {
        for (let group of groupedFlights) {
            if (group.flights.length > 0) {
                return group.monthYear;
            }
        }
        return null;
    };

    const flightsByMonth: FlightsByMonth[] = groupFlightsByMonth(filteredFlights);

    function calculateTotalEngineTime(flights: Flight[]) {
        const totalEngineTime = flights.reduce((total, flight) => {
            return total + flight.engine_time_difference;
        }, 0);

        const roundedTotalEngineTime = totalEngineTime.toFixed(1);

        return `${roundedTotalEngineTime} MTH`;
    }

    // Filtered pilots based on query
    const filteredPilots =
        pilotQuery === ""
            ? pilots
            : pilots.filter((pilot) =>
                `${pilot.first_name} ${pilot.last_name}`
                    .toLowerCase()
                    .includes(pilotQuery.toLowerCase())
            );

    // Function to toggle expanded month
    const toggleMonth = (month: string) => {
        setExpandedMonth(expandedMonth === month ? null : month);
    };

    return (
        <main>
            <div className="pb-4">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>Flights</Heading>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                        <span className="hidden sm:block sm:ml-3">
                            <Button outline onClick={handleExportToExcel}>
                                Export to XLS
                            </Button>
                        </span>
                        <span className="sm:ml-3">
                            <Button onClick={handleAddFlight}>
                                <PlusCircleIcon className="h-5 w-5 mr-2"/>
                                Add Flight
                            </Button>
                        </span>
                        <div className="relative ml-3 sm:hidden">
                            <Dropdown>
                                <DropdownButton outline>
                                    More
                                    <ChevronDownIcon className="ml-2 h-5 w-5"/>
                                </DropdownButton>
                                <DropdownMenu>
                                    <DropdownItem onClick={handleExportToExcel}>
                                        <DropdownLabel>Export to XLS</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            {/* Filters */}
            <section
                aria-labelledby="filter-heading"
                className="grid items-center border-b border-t border-zinc-200 dark:border-zinc-800"
            >
                <Subheading id="filter-heading" className="sr-only">
                    Filters
                </Subheading>
                <div className="relative col-start-1 row-start-1 py-4">
                    <div className="mx-auto flex space-x-6 divide-x divide-gray-200 text-sm ">
                        <div>
                            <Button
                                outline
                                onClick={() => setShowFilters(!showFilters)}
                                className="group flex items-center font-medium text-gray-700"
                            >
                                <FunnelIcon
                                    aria-hidden="true"
                                    className="mr-2 h-5 w-5 flex-none group-hover:text-gray-500"
                                />
                                Filters
                            </Button>
                        </div>
                    </div>
                </div>

                {showFilters && (
                    <div className="border-t border-gray-200 py-4 dark:border-gray-900">
                        <div className="mx-auto grid gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-3 md:gap-x-6">
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <Label>Date and Time Range</Label>
                                            <Input
                                                type="datetime-local"
                                                value={startDateTime}
                                                onChange={(e) => handleStartDateChange(e.target.value)}
                                            />
                                            <Input
                                                className="mt-4"
                                                type="datetime-local"
                                                value={endDateTime}
                                                onChange={(e) => handleEndDateChange(e.target.value)}
                                            />
                                        </Field>
                                    </FieldGroup>
                                </Fieldset>
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <Label>Pilot in Command</Label>
                                            {/* Custom Combobox */}
                                            <div className="relative">
                                                <Input
                                                    type="text"
                                                    placeholder="Search pilots..."
                                                    className="mt-3"
                                                    value={
                                                        selectedPilot
                                                            ? `${selectedPilot.first_name} ${selectedPilot.last_name}`
                                                            : pilotQuery
                                                    }
                                                    onChange={(e) => {
                                                        setPilotQuery(e.target.value);
                                                        setSelectedPilot(null);
                                                    }}
                                                />
                                                {pilotQuery && (
                                                    <div
                                                        className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto dark:bg-zinc-800 dark:border-zinc-700">
                                                        {filteredPilots.slice(0, 5).map((pilot) => (
                                                            <div
                                                                key={pilot.uuid}
                                                                className="cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-zinc-700"
                                                                onClick={() => {
                                                                    setSelectedPilot(pilot);
                                                                    setPilotQuery("");
                                                                }}
                                                            >
                                                                {pilot.first_name} {pilot.last_name}
                                                            </div>
                                                        ))}
                                                        {filteredPilots.length === 0 && (
                                                            <div className="px-4 py-2 text-gray-500">
                                                                No pilots found.
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Field>
                                    </FieldGroup>
                                </Fieldset>
                                <Fieldset>
                                    <FieldGroup>
                                        <Field>
                                            <Label>Remarks</Label>
                                            <Input
                                                type="text"
                                                value={remarksQuery}
                                                onChange={(e) => setRemarksQuery(e.target.value)}
                                            />
                                        </Field>
                                    </FieldGroup>
                                </Fieldset>
                            </div>
                        </div>
                    </div>
                )}
            </section>

            {/* Main Content */}
            {isInitialized ? (
                currentOrganizationId ? (
                    flightsByMonth.length === 0 ? (
                        <div className="mt-8 text-center text-zinc-500 dark:text-zinc-400">
                            {error ? error : "No flights available."}
                        </div>

                    ) : (
                        flightsByMonth.map(({monthYear, flights: monthlyFlights}) => (
                            <div key={monthYear} className="mt-8">
                                <div
                                    className="border-b border-gray-200 pb-5 cursor-pointer dark:border-gray-900"
                                    onClick={() => toggleMonth(monthYear)}
                                >
                                    <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                        {expandedMonth === monthYear ? (
                                            <ChevronDownIcon className="ml-2 mt-2 h-5 w-5 text-gray-500"/>
                                        ) : (
                                            <ChevronRightIcon className="ml-2 mt-2 h-5 w-5 text-gray-500"/>
                                        )}
                                        <h3 className="ml-2 mt-2 text-lg font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                            {monthYear}
                                        </h3>
                                        <p className="ml-2 mt-1 truncate text-xs text-gray-500 dark:text-gray-200">
                                            {calculateTotalEngineTime(monthlyFlights)}
                                        </p>
                                    </div>
                                </div>
                                {expandedMonth === monthYear && (
                                    <div>
                                        <Table dense>
                                            <TableHead>
                                                <TableRow>
                                                    <TableHeader>Aircraft and Date</TableHeader>
                                                    <TableHeader>Pilot In Command</TableHeader>
                                                    <TableHeader>Route</TableHeader>
                                                    <TableHeader>Flight / Ground</TableHeader>
                                                    <TableHeader>Engine</TableHeader>
                                                    <TableHeader>Oil</TableHeader>
                                                    <TableHeader>Fuel</TableHeader>
                                                    <TableHeader>Remarks</TableHeader>
                                                    <TableHeader></TableHeader>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {monthlyFlights.map((flight) => (
                                                    <TableRow key={flight.uuid}>
                                                        <TableCell>
                                                            <div
                                                                className="font-medium text-gray-900 dark:text-gray-100">
                                                                {flight.aircraft_registration}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                {formatDate(flight.flight_date)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div
                                                                className="font-medium text-gray-900 dark:text-gray-100">
                                                                {flight.pilot_in_command}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                {flight.license_number}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="text-gray-900 dark:text-gray-100">
                                                                {flight.departure_airfield} {flight.departure_time}
                                                            </div>
                                                            <div className="mt-1 text-gray-900 dark:text-gray-100">
                                                                {flight.arrival_airfield} {flight.arrival_time}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="text-gray-900 dark:text-gray-100">
                                                                {flight.flight_time} / {flight.ground_time}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                Landings {flight.number_of_landings}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="text-gray-900 dark:text-gray-100 ">
                                                                {flight.engine_time_before_flight} -{" "}
                                                                {flight.engine_time_after_flight}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                Used {flight.engine_time_difference}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="text-gray-900 dark:text-gray-100">
                                                                {flight.oil_on_inspection} / {flight.oil_on_departure}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                Added {flight.oil_added}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div className="text-gray-900 dark:text-gray-100">
                                                                {flight.fuel_on_inspection} + {flight.fuel_added};
                                                                TO:{" "}
                                                                {flight.fuel_on_departure}
                                                            </div>
                                                            <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                                Landing {flight.fuel_on_landing}; Used{" "}
                                                                {flight.fuel_used}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <div
                                                                className="text-gray-900 dark:text-gray-100">{flight.remarks}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            {sessionStorage.getItem("user_role") === "admin" && (
                                                                <Button
                                                                    onClick={() => handleEditFlight(flight.uuid)}
                                                                >
                                                                    Edit
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                            </div>
                        ))
                    )
                ) : (
                    // Loading indicator while polling
                    <div className="mt-8 text-center text-zinc-500 dark:text-zinc-400">
                        Loading organization data...
                    </div>
                )
            ) : (
                // Optionally, you can add another loading state here if needed
                <div className="mt-8 text-center text-zinc-500 dark:text-zinc-400">
                    Initializing...
                </div>
            )}

            {/* Display error messages */}
            {isInitialized && !currentOrganizationId && (
                <div className="text-red-600 px-4 sm:px-6 lg:px-8 mt-4">
                    No organization selected. Please select an organization.
                </div>
            )}
            {error && (
                <div className="text-red-600 px-4 sm:px-6 lg:px-8 mt-4">{error}</div>
            )}
        </main>
    );

};

export default FlightManagementSection;
