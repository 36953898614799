// EditFlightWrapper.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from "../../tools/AxiosInterceptor";
import { Flight } from "../../types";
import AddFlightSection from './AddEditFlightSection/AddFlightSection';

const EditFlightWrapper: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [flightData, setFlightData] = useState<Flight | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchFlight = async () => {
            try {
                const response = await apiClient.get(`/v1/getFlight/${uuid}`, { // Ensure this endpoint exists in your backend
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                });
                setFlightData(response.data);
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError("An unknown error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        if (uuid) {
            fetchFlight();
        } else {
            setError("No flight UUID provided.");
            setLoading(false);
        }
    }, [uuid]);

    if (loading) {
        return <div>Loading flight data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!flightData) {
        return <div>No flight data found.</div>;
    }

    return <AddFlightSection flightData={flightData} />; // Pass flightData as a prop
};

export default EditFlightWrapper;
