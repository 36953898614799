import React, { createContext, useContext, useState, ReactNode } from "react";

interface Organization {
    id: string;
    name: string;
}

interface User {
    uuid: string;
    role: string;
    organizationIds: string[];
}

interface AppContextType {
    organizations: Organization[];
    currentOrganization: Organization | null;
    user: User | null;
    setOrganizations: (orgs: Organization[]) => void;
    setCurrentOrganization: (org: Organization) => void;
    setUser: (user: User) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);
    const [user, setUser] = useState<User | null>(null);

    return (
        <AppContext.Provider
            value={{
                organizations,
                currentOrganization,
                user,
                setOrganizations,
                setCurrentOrganization,
                setUser,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = (): AppContextType => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};
