// useAircrafts.ts

import { useState, useEffect } from 'react';
import apiClient from '../tools/AxiosInterceptor';
import { Aircraft } from '../types';

const useAircrafts = () => {
    const [aircrafts, setAircrafts] = useState<Aircraft[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAircrafts = async () => {
            try {
                const response = await apiClient.get('/v1/getAircraft', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setAircrafts(response.data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            }
        };
        fetchAircrafts();
    }, []);

    return { aircrafts, error };
};

export default useAircrafts;
