// usePilots.ts

import { useState, useEffect } from 'react';
import apiClient from '../tools/AxiosInterceptor';
import { User } from '../types';

interface UsersResponse {
    users: User[];
}

const usePilots = () => {
    const [pilots, setPilots] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPilots = async () => {
            try {
                const response = await apiClient.get<UsersResponse>('/getUsers', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setPilots(response.data.users);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            }
        };
        fetchPilots();
    }, []);

    return { pilots, error };
};

export default usePilots;
