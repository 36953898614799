import React, {useEffect, useState} from 'react';
import apiClient from "../../../tools/AxiosInterceptor";
import {UserCircleIcon, ListBulletIcon} from '@heroicons/react/24/outline';
import md5 from "md5";
import {User, Flight} from "../../../types";
import {Heading} from "../../../catalystui/heading";
import {Badge} from "../../../catalystui/badge";
import UserFlightsTable from "./UserFlightListSection";
import {Divider} from "../../../catalystui/divider";
import {useParams} from "react-router-dom";
import {DescriptionDetails, DescriptionList, DescriptionTerm} from "../../../catalystui/description-list";
import {Avatar} from "../../../catalystui/avatar";

const UserProfileSection = () => {
    const {uuid: routeUuid} = useParams<{ uuid: string }>(); // Get UUID from route if available
    const [user, setUser] = useState<User | null>(null);
    const [userFlights, setUserFlights] = useState<Flight[]>([]);
    const [activeSection, setActiveSection] = useState<string>('Profile');
    const [allUserMTH, setAllUserMTH] = useState<{ uuid: string, mth: number }[]>([]);
    const [allUserFlightTime, setAllUserFlightTime] = useState<{ uuid: string, flightTime: number }[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    type BadgeColor =
        | 'green'
        | 'orange'
        | 'rose'
        | 'zinc'
        | 'indigo'
        | 'cyan'
        | 'red'
        | 'amber'
        | 'yellow'
        | 'lime'
        | 'emerald'
        | 'teal'
        | 'sky'
        | 'blue'
        | 'violet'
        | 'purple'
        | 'fuchsia'
        | 'pink';

    const getEmailHash = (email: string) => {
        return md5(email.trim().toLowerCase());
    };
    const getExpiryColor = (days: number): BadgeColor => {
        if (days > 90) return 'lime';
        else if (days > 30) return 'orange';
        else return 'rose';
    };
    const getDaysTillExpiry = (dateString: string | undefined): number => {
        if (!dateString) {
            return 0; // Default value for missing or invalid dates
        }

        const expiryDate = new Date(dateString);

        if (isNaN(expiryDate.getTime())) {
            return 0; // Default value for invalid dates
        }

        const today = new Date();
        const timeDiff = expiryDate.getTime() - today.getTime();
        const daysTillExpiry = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return daysTillExpiry;
    };
    const userEmail = sessionStorage.getItem('email');
    const imageSize = 200;
    const gravatarUrl = userEmail
        ? `https://www.gravatar.com/avatar/${getEmailHash(userEmail)}?s=${imageSize}`
        : `https://www.gravatar.com/avatar/?d=identicon&s=${imageSize}`;

    const handleChangeAvatar = () => {
        window.open('https://en.gravatar.com/emails/', '_blank');
    };

    const formatTimeInHHMM = (totalMinutes: number): string => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    };

    const padToTwoDigits = (number: number): string => {
        return number.toString().padStart(2, '0');
    };


    const calculateTotalFlightTime = (flights: Flight[]): number => {
        return flights.reduce((total: number, flight: Flight) => {
            return total + parseFlightTimeToMinutes(flight.flight_time);
        }, 0);
    };

    const calculateTotalMTH = (flights: Flight[]): number => {
        return flights.reduce((total: number, flight: Flight) => total + flight.engine_time_difference, 0);
    };

    const parseFlightTimeToMinutes = (timeString: string): number => {
        if (!timeString || !timeString.includes(':')) {
            console.warn(`Invalid flight time format: "${timeString}"`);
            return 0; // Return 0 for invalid or missing time strings
        }

        const [hours, minutes] = timeString.split(':').map(Number);

        // Ensure hours and minutes are valid numbers
        if (isNaN(hours) || isNaN(minutes)) {
            console.warn(`Invalid flight time values: "${timeString}"`);
            return 0;
        }

        return hours * 60 + minutes;
    };


    useEffect(() => {
        const fetchUserDataAndFlights = async () => {
            try {
                const userUuid = routeUuid || sessionStorage.getItem('user_uuid'); // Use route UUID or fallback to session
                if (!userUuid) {
                    console.error("User UUID not found in sessionStorage");
                    return;
                }

                setLoading(true);

                const [userResponse, flightsResponse] = await Promise.all([
                    apiClient.get<User>(`getUser/${userUuid}`, {
                        headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
                    }),
                    apiClient.get<Flight[]>('/getFlights', {
                        headers: {'Authorization': `Bearer ${sessionStorage.getItem('token')}`}
                    })
                ]);

                setUser(userResponse.data);

                const userFlights = flightsResponse.data.filter(flight => flight.pic_user_uuid === userUuid);
                setUserFlights(userFlights);

                const allFlights = flightsResponse.data;

                // Calculate total MTH for all users
                const mthData = allFlights.reduce((acc: { [key: string]: number }, flight) => {
                    acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + flight.engine_time_difference;
                    return acc;
                }, {});

                const flightTimeData = allFlights.reduce((acc: { [key: string]: number }, flight) => {
                    acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + parseFlightTimeToMinutes(flight.flight_time);
                    return acc;
                }, {});

                setAllUserMTH(Object.keys(mthData).map(uuid => ({uuid, mth: mthData[uuid]})));
                setAllUserFlightTime(Object.keys(flightTimeData).map(uuid => ({
                    uuid,
                    flightTime: flightTimeData[uuid]
                })));

                setLoading(false);
            } catch (error) {
                console.error("Error fetching user or flights data:", error);
                setLoading(false);
                setError("Failed to fetch data. Please try again later.");
            }
        };

        fetchUserDataAndFlights();
    }, []);

    const getUserMTHRanking = (): number | null => {
        if (!user) return null;
        const sortedMTHList = [...allUserMTH].sort((a, b) => b.mth - a.mth);
        return sortedMTHList.findIndex(entry => entry.uuid === user.uuid) + 1;
    };

    const getUserFlightTimeRanking = (): number | null => {
        if (!user) return null;

        // Calculate the user's total flight time
        const userTotalFlightTime = calculateTotalFlightTime(userFlights);
        console.log("User Total Flight Time (in minutes):", userTotalFlightTime);

        // Ensure the array is sorted correctly for ranking
        const sortedFlightTimeList = [...allUserFlightTime].sort((a, b) => b.flightTime - a.flightTime);
        console.log("Sorted Flight Time List:", sortedFlightTimeList);

        // Find the current user's rank based on their UUID
        const userRank = sortedFlightTimeList.findIndex(entry => entry.uuid === user.uuid) + 1;
        console.log("User UUID:", user.uuid);
        console.log("User Rank by Flight Time:", userRank);

        return userRank;
    };

    const userMTH = calculateTotalMTH(userFlights);
    const userMTHRanking = getUserMTHRanking();
    const userFlightTime = calculateTotalFlightTime(userFlights);
    const userFlightTimeRanking = getUserFlightTimeRanking();

    const getGravatarUrl = (email: string) => {
        const hash = md5(email.trim().toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=mp`;
    };

    return (
        <div>
            <Heading><Avatar onClick={()=>handleChangeAvatar()} src={getGravatarUrl(user?.email || '')}
                             className="size-12 mr-4"/>{routeUuid ? `${user?.first_name} ${user?.last_name}` : `Hello, ${user?.first_name}`}
            </Heading>

            <div className="mt-4 grid gap-8 sm:grid-cols-3 xl:grid-cols-6">
                <div>
                    <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                    <div
                        className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">{routeUuid ? `Total MTH` : 'Your Total MTH'}</div>
                    <div
                        className="mt-3 text-2xl font-semibold text-gray-900 dark:text-gray-100">{userMTH.toFixed(1)}</div>
                    <div className="mt-3 text-sm">
                        <Badge color="lime">Rank {userMTHRanking}</Badge>
                    </div>
                </div>
                <div>
                    <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                    <div
                        className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">{routeUuid ? `Total Flight Time` : 'Your Total MTH'}</div>
                    <div
                        className="mt-3 text-2xl font-semibold text-gray-900 dark:text-gray-100">{formatTimeInHHMM(userFlightTime)}</div>
                    <div className="mt-3 text-sm">
                        <Badge color="lime">Rank {userFlightTimeRanking}</Badge>
                    </div>
                </div>
                {user?.pilot?.medical?.validTill ? (
                    <div>
                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                        <div
                            className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">Medical
                        </div>
                        <div
                            className="mt-3 text-sm text-gray-500 dark:text-gray-500">Expiration date:
                        </div>
                        <div
                            className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{new Date(user.pilot.medical.validTill).toLocaleDateString()}</div>
                        <div className="mt-3 text-sm">
                            <Badge
                                color={getExpiryColor(getDaysTillExpiry(user.pilot.medical.validTill))}
                            >
                                {getDaysTillExpiry(user.pilot.medical.validTill)} days
                            </Badge>
                        </div>


                    </div>
                ) : (
                    <div>
                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                        <div
                            className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">No valid medical
                        </div>
                    </div>
                )}
                {user?.pilot?.ratings && user.pilot.ratings.length > 0 ? (
                    user.pilot.ratings.map((rating, index) => (
                        <div key={index}>
                            <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                            <div
                                className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">{rating.ratingName}</div>
                            <div
                                className="mt-3 text-sm text-gray-500 dark:text-gray-500">Expiration date:
                            </div>
                            <div
                                className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{new Date(rating.validTill).toLocaleDateString()}</div>
                            <div className="mt-3 text-sm">
                                <Badge
                                    color={getExpiryColor(getDaysTillExpiry(rating.validTill))}
                                >
                                    {getDaysTillExpiry(rating.validTill)} days
                                </Badge>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>
                        <hr className="w-full border-t border-zinc-950/10 dark:border-white/10"/>
                        <div
                            className="mt-6 text-md font-medium text-gray-900 dark:text-gray-100">No valid ratings
                        </div>
                    </div>
                )}
            </div>
            <Heading className="mt-6">Flights</Heading>
            <Divider className="mt-3"/>
            <UserFlightsTable flights={userFlights}/>

            {loading && <p>Loading data...</p>}
            {error && <p className="text-red-500">{error}</p>}
        </div>
    );
};

export default UserProfileSection;
