import React, {useEffect, useState} from "react";
import apiClient from "../../../tools/AxiosInterceptor";
import {
    PlusCircleIcon,
    PencilIcon,
    TrashIcon,
    EllipsisVerticalIcon, EllipsisHorizontalIcon
} from "@heroicons/react/20/solid";
import FuelTransactionForm from "./AddEditViewForms/FuelTransactionForm";
import {Heading} from "../../../catalystui/heading";
import {Button} from "../../../catalystui/button";
import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownLabel,
    DropdownMenu
} from "../../../catalystui/dropdown";
import {FuelTransaction} from "../../../types";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const FuelManagementSection: React.FC = () => {
    const [fuelTransactions, setFuelTransactions] = useState<FuelTransaction[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentTransaction, setCurrentTransaction] = useState<FuelTransaction | undefined>(undefined);
    const [latestTransaction, setLatestTransaction] = useState<FuelTransaction | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchFuelTransactions();
    }, []);

    const fetchFuelTransactions = async () => {
        try {
            const response = await apiClient.get('/getFuelTransactions', {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);
            const sortedTransactions = response.data.transactions.sort((a: FuelTransaction, b: FuelTransaction) => new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime());
            setFuelTransactions(sortedTransactions);
            setLatestTransaction(sortedTransactions[0]);
        } catch (err: any) {
            setError(err.message || "Unable to fetch fuel transactions.");
        }
    };

    const handleEditFuelTransaction = (transaction: FuelTransaction) => {
        setCurrentTransaction(transaction);
        setIsEditing(true);
    };

    const handleAddTransactionClick = () => {
        setCurrentTransaction(undefined);
        setIsEditing(true);
    };

    const handleTransactionSavedOrCancelled = () => {
        setIsEditing(false);
        setCurrentTransaction(undefined);
        fetchFuelTransactions();
    };

    const handleDeleteFuelTransaction = async (transactionUuid: string) => {
        try {
            await apiClient.delete(`/deleteFuelTransaction/${transactionUuid}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            } as any);

            // Update state to remove the deleted transaction
            setFuelTransactions(prevTransactions => prevTransactions.filter(t => t.uuid !== transactionUuid));
        } catch (error: any) {
            setError(error.message || "Unable to delete transaction.");
        }
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('pl-PL');
    };

    return (
        <main>
            {isEditing ? (
                <FuelTransactionForm
                    fuelTransactionData={currentTransaction}
                    latestTransaction={latestTransaction}
                    onSaveOrCancel={handleTransactionSavedOrCancelled}
                />
            ) : (
                <>
                    <div className="pb-4">
                        <div className="lg:flex lg:items-center lg:justify-between">
                            <div className="min-w-0 flex-1">
                                <Heading>
                                    Fuel Transactions
                                </Heading>
                            </div>
                            <div className="mt-5 flex lg:ml-4 lg:mt-0">
                                    <span className="sm:ml-3">
                                        <Button
                                            onClick={handleAddTransactionClick}
                                        >
                                            <PlusCircleIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true"/>
                                            Add Transaction
                                        </Button>
                                    </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300 dark:divide-zinc-800">
                                        <thead className="bg-gray-50 dark:bg-zinc-700">
                                        <tr>
                                            <th scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 lg:pl-8 dark:text-gray-100">
                                                Date
                                            </th>
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 dark:text-gray-100">
                                                User
                                            </th>
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 dark:text-gray-100">
                                                Transaction
                                            </th>
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 dark:text-gray-100">
                                                Amount Before
                                            </th>
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 dark:text-gray-100">
                                                Amount After
                                            </th>
                                            <th scope="col"
                                                className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 dark:text-gray-100">
                                                Price
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white dark:divide-zinc-700 dark:bg-zinc-800">
                                        {fuelTransactions.map((item, itemIdx) => (
                                            <tr key={item.uuid}>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200 ' : '',
                                                    'py-2 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 lg:pl-8 dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                    {formatDate(item.transactionDate)}
                                                </td>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'px-3 py-2 text-xs text-gray-500 sm:table-cell dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                    {item.transactionUser}
                                                </td>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'px-3 py-2 text-xs text-gray-500 sm:table-cell dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                            <span className={classNames(
                                                                item.transactionType === "Added" ? "text-green-600" : "text-red-600"
                                                            )}>
                                                                {item.transactionType} {item.transactionAmount} {item.transactionUnits}
                                                            </span>
                                                </td>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'px-3 py-2 text-xs text-gray-500 sm:table-cell dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                    {item.transactionStatusBefore} {item.transactionUnits}
                                                </td>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'px-3 py-2 text-xs text-gray-500 sm:table-cell dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                    {item.transactionStatusAfter} {item.transactionUnits}
                                                </td>
                                                <td className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'px-3 py-2 text-xs text-gray-500 sm:table-cell dark:text-gray-100 dark:border-zinc-700'
                                                )}>
                                                    {item.transactionAmount * item.transactionNetCostPerUnit} {item.transactionCurrency}
                                                </td>
                                                <td
                                                    className={classNames(
                                                    itemIdx !== fuelTransactions.length - 1 ? 'border-b border-gray-200' : '',
                                                    'relative whitespace-nowrap py-2 pr-4 text-right text-xs font-medium sm:pr-6 lg:pr-8 dark:text-gray-100 dark:border-zinc-700')}
                                                >
                                                    <Dropdown>
                                                        <DropdownButton outline className="size-6">
                                                            <EllipsisHorizontalIcon />
                                                        </DropdownButton>
                                                        <DropdownMenu className="min-w-64" anchor="bottom end">
                                                            <DropdownItem onClick={() => handleEditFuelTransaction(item)}>
                                                                <PencilIcon />
                                                                <DropdownLabel>Edit</DropdownLabel>
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleDeleteFuelTransaction(item.uuid)}>
                                                                <TrashIcon />
                                                                <DropdownLabel>Delete</DropdownLabel>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {error && <div className="text-red-600">{error}</div>}
        </main>
    );
};

export default FuelManagementSection;
