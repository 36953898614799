import React, {useEffect, useState} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import UserListSection from './User/UserListSection';
import FlightManagementSection from './FlightManagementSection';
import FuelManagementSection from './Fuel/FuelManagementSection';
import UserProfileSection from './User/UserProfileSection';
import AddFlightSection from './AddEditFlightSection/AddFlightSection';
import EditFlightWrapper from './EditFlightWrapper';
import FlightPlanningSection from './FlightPlanning/FlightPlanningSection';
import {StackedLayout} from '../../catalystui/stacked-layout';
import {Navbar, NavbarDivider, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer} from '../../catalystui/navbar';
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../../catalystui/dropdown';
import {Avatar} from '../../catalystui/avatar';
import {ArrowRightStartOnRectangleIcon, UserIcon} from '@heroicons/react/16/solid';
import {Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection} from '../../catalystui/sidebar';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import md5 from 'md5';
import ToggleThemeButton from '../ToggleThemeButton';
import AircraftList from './Aircraft/AircraftList';
import AircraftDetailsWrapper from './AircraftDetailsWrapper';
import ComponentDetails from './Aircraft/ComponentDetails';
import AircraftIssueSection from './Aircraft/AddEditViewForms/AircraftIssueSection';
import MGAero_logo from '../../assets/new_logo_192.png';
import apiClient from '../../tools/AxiosInterceptor';
import AddEditAircraft from "./Aircraft/AddEditViewForms/AddEditAircraftForm";


interface Organization {
    id: string;
    name: string;
    description?: string;
}

const DashboardScreen: React.FC = () => {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [currentOrganization, setCurrentOrganization] = useState<Organization | null>(null);

    useEffect(() => {
        const fetchOrganizations = async () => {
            const storedOrgIds = sessionStorage.getItem('organizationIds');
            const token = sessionStorage.getItem('token');

            if (storedOrgIds && token) {
                try {
                    const orgIds: string[] = JSON.parse(storedOrgIds);
                    const orgPromises = orgIds.map((id) =>
                        apiClient.get<Organization>(`/v1/organizations/${id}`, {
                            headers: {Authorization: `Bearer ${token}`},
                        })
                    );

                    const responses = await Promise.all(orgPromises);
                    const orgData = responses.map((res) => res.data);
                    setOrganizations(orgData);

                    const storedSelectedOrg = sessionStorage.getItem('selectedOrg');
                    if (storedSelectedOrg) {
                        setCurrentOrganization(JSON.parse(storedSelectedOrg));
                    } else if (orgData.length > 0) {
                        setCurrentOrganization(orgData[0]);
                        sessionStorage.setItem('selectedOrg', JSON.stringify(orgData[0]));
                    }
                } catch (error) {
                    console.error('Error fetching organizations:', error);
                }
            }
        };

        fetchOrganizations();
    }, []);

    useEffect(() => {
        if (currentOrganization) {
            sessionStorage.setItem('selectedOrg', JSON.stringify(currentOrganization));
        }
    }, [currentOrganization]);

    const handleOrgChange = (orgId: string) => {
        const selectedOrg = organizations.find((org) => org.id === orgId);
        if (selectedOrg) {
            setCurrentOrganization(selectedOrg);
            // Add logic to fetch/update organization-specific data if necessary
        }
    };

    const handleSignOut = () => {
        sessionStorage.clear();
        window.location.href = '/';
    };

    const navigation = [
        {name: 'Flights', href: '/dashboard/flights'},
        {name: 'Aircraft', href: '/dashboard/aircraft'},
        {name: 'Fuel', href: '/dashboard/fuel'},
        {name: 'Team', href: '/dashboard/team'},
        {name: 'Flight Planning', href: '/dashboard/flight-planning'},
    ];

    const userEmail = sessionStorage.getItem('email');
    const gravatarUrl = userEmail
        ? `https://www.gravatar.com/avatar/${md5(userEmail.trim().toLowerCase())}`
        : 'https://www.gravatar.com/avatar/?d=identicon';

    return (
        <div className="min-h-full">
            <StackedLayout
                navbar={
                    <Navbar>
                        {organizations.length > 1 && (
                            <>
                                <Dropdown>
                                    <DropdownButton as={NavbarItem} className="max-lg:hidden">
                                        <Avatar src={MGAero_logo}/>
                                        <NavbarLabel>{currentOrganization?.name}</NavbarLabel>
                                        <ChevronDownIcon/>
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {organizations.map((org) => (
                                            <DropdownItem key={org.id} onClick={() => handleOrgChange(org.id)}>
                                                {org.name}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                                <NavbarDivider className="max-lg:hidden"/>
                            </>
                        )}
                        <NavbarSection className="max-lg:hidden">
                            {navigation.map(({name, href}) => (
                                <NavbarItem key={name} href={href}>
                                    {name}
                                </NavbarItem>
                            ))}
                        </NavbarSection>
                        <NavbarSpacer/>
                        <NavbarSection>
                            <NavbarItem aria-label="Toggle Theme">
                                <ToggleThemeButton/>
                            </NavbarItem>
                            <Dropdown>
                                <DropdownButton as={NavbarItem}>
                                    <Avatar src={gravatarUrl} square/>
                                </DropdownButton>
                                <DropdownMenu className="min-w-64" anchor="bottom end">
                                    <DropdownItem href="/dashboard/userprofile">
                                        <UserIcon/>
                                        <DropdownLabel>My profile</DropdownLabel>
                                    </DropdownItem>
                                    <DropdownDivider/>
                                    <DropdownItem onClick={handleSignOut}>
                                        <ArrowRightStartOnRectangleIcon/>
                                        <DropdownLabel>Sign out</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </NavbarSection>
                    </Navbar>
                }
                sidebar={
                    <Sidebar>
                        {organizations.length > 1 && (
                            <SidebarHeader>
                                <Dropdown>
                                    <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                                        <Avatar src={MGAero_logo}/>
                                        <SidebarLabel>{currentOrganization?.name}</SidebarLabel>
                                        <ChevronDownIcon/>
                                    </DropdownButton>
                                    <DropdownMenu>
                                        {organizations.map((org) => (
                                            <DropdownItem key={org.id} onClick={() => handleOrgChange(org.id)}>
                                                {org.name}
                                            </DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            </SidebarHeader>
                        )}
                        <SidebarBody>
                            <SidebarSection>
                                {navigation.map(({name, href}) => (
                                    <SidebarItem key={name} href={href}>
                                        {name}
                                    </SidebarItem>
                                ))}
                            </SidebarSection>
                        </SidebarBody>
                    </Sidebar>
                }
            >
                <Routes>
                    <Route path="flights" element={<FlightManagementSection/>}/>
                    <Route path="flights/add" element={<AddFlightSection/>}/>
                    <Route path="flights/edit/:uuid" element={<EditFlightWrapper/>}/>
                    <Route path="fuel" element={<FuelManagementSection/>}/>
                    <Route path="aircraft" element={<AircraftList/>}/>
                    <Route path="aircraft/add" element={<AddEditAircraft/>}/>
                    <Route path="aircraft/:uuid" element={<AircraftDetailsWrapper/>}/>
                    <Route path="aircraft/:uuid/component/:componentUuid" element={<ComponentDetails/>}/>
                    <Route path="aircraft/:uuid/issues/" element={<AircraftIssueSection/>}/>
                    <Route path="team" element={<UserListSection/>}/>
                    <Route path="userprofile" element={<UserProfileSection/>}/>
                    <Route path="user/:uuid" element={<UserProfileSection/>}/>
                    <Route path="flight-planning" element={<FlightPlanningSection/>}/>
                </Routes>
            </StackedLayout>
        </div>
    );
};

export default DashboardScreen;
