import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AircraftDetails from './Aircraft/AircraftDetails';
import { Aircraft } from '../../types'; // Adjust the import path as needed
import apiClient from "../../tools/AxiosInterceptor"; // Adjust the import path based on your project structure

interface ExpiringDocument {
    registration: string;
    documentType: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
}
const AircraftDetailsWrapper: React.FC = () => {
    const { uuid } = useParams<{ uuid: string }>();
    const [aircraft, setAircraft] = useState<Aircraft | null>(null);
    const [expiringDocuments, setExpiringDocuments] = useState<ExpiringDocument[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAircraft = async () => {
            try {
                const token = sessionStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found. Please log in.');
                }

                // Fetch aircraft details
                const aircraftResponse = await apiClient.get<Aircraft>(`/v1/getAircraft/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    params: {uuid: uuid}
                });

                console.log(aircraftResponse.data);
                setAircraft(aircraftResponse.data);

                // Fetch expiring documents and upcoming activities
                const expiringDocsResponse = await apiClient.get<{ upcomingActivities: ExpiringDocument[] }>(
                    `/v1/getExpiringDocumentsAndUpcomingActivities/`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                        params: {uuid: uuid}
                    }
                );

                setExpiringDocuments(expiringDocsResponse.data.upcomingActivities || []);
            } catch (err: unknown) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError("An unknown error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        if (uuid) {
            fetchAircraft();
        } else {
            setError("No aircraft UUID provided.");
            setLoading(false);
        }
    }, [uuid]);

    if (loading) {
        return <div className="p-4 text-center">Loading...</div>;
    }

    if (error) {
        return <div className="p-4 text-center text-red-500">Error: {error}</div>;
    }

    if (!aircraft) {
        return <div className="p-4 text-center">No aircraft found.</div>;
    }

    return <AircraftDetails aircraft={aircraft} expiringDocuments={expiringDocuments} />;
};

export default AircraftDetailsWrapper;
