import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from "../../../catalystui/table";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface Flight {
    uuid: string;
    aircraft_registration: string;
    flight_date: string;
    pilot_in_command: string;
    license_number: string;
    departure_airfield: string;
    arrival_airfield: string;
    departure_time: string;
    arrival_time: string;
    flight_time: string;
    ground_time: string;
    number_of_landings: number;
    engine_time_before_flight: number;
    engine_time_after_flight: number;
    engine_time_difference: number;
    oil_on_inspection: number;
    oil_added: number;
    oil_on_departure: number;
    fuel_on_inspection: number;
    fuel_added: number;
    fuel_on_departure: number;
    fuel_on_landing: number;
    fuel_used: number;
    remarks?: string;
}

interface UserFlightsTableProps {
    flights: Flight[];
}

const UserFlightsTable: React.FC<UserFlightsTableProps> = ({ flights }) => {
    const [expandedMonth, setExpandedMonth] = useState<string | null>(null);

    const toggleMonth = (month: string) => {
        setExpandedMonth(expandedMonth === month ? null : month);
    };

    const groupFlightsByMonth = (flights: Flight[]): Record<string, Flight[]> => {
        return flights.reduce((groups: Record<string, Flight[]>, flight) => {
            const monthYear = new Date(flight.flight_date).toLocaleString('default', { month: 'long', year: 'numeric' });
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(flight);
            return groups;
        }, {});
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const flightsByMonth = groupFlightsByMonth(flights);

    // Sort the months in descending order by date
    const sortedMonths = Object.entries(flightsByMonth).sort(
        ([monthA], [monthB]) => new Date(monthB).getTime() - new Date(monthA).getTime()
    );

    return (
        <div>
            {sortedMonths.map(([monthYear, monthlyFlights]) => (
                <div key={monthYear} className="mt-8">
                    <div
                        className="border-b border-gray-200 pb-5 cursor-pointer dark:border-gray-900"
                        onClick={() => toggleMonth(monthYear)}
                    >
                        <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
                            {expandedMonth === monthYear ? (
                                <ChevronDownIcon className="ml-2 mt-2 h-5 w-5 text-gray-500" />
                            ) : (
                                <ChevronRightIcon className="ml-2 mt-2 h-5 w-5 text-gray-500" />
                            )}
                            <h3 className="ml-2 mt-2 text-lg font-semibold leading-6 text-gray-900 dark:text-gray-100">
                                {monthYear}
                            </h3>
                        </div>
                    </div>
                    {expandedMonth === monthYear && (
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeader>Aircraft and Date</TableHeader>
                                        <TableHeader>Route</TableHeader>
                                        <TableHeader>Flight / Ground</TableHeader>
                                        <TableHeader>Engine</TableHeader>
                                        <TableHeader>Fuel</TableHeader>
                                        <TableHeader>Remarks</TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {monthlyFlights.map((flight) => (
                                        <TableRow key={flight.uuid}>
                                            <TableCell>
                                                <div className="font-medium text-gray-900 dark:text-gray-100">
                                                    {flight.aircraft_registration}
                                                </div>
                                                <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                    {formatDate(flight.flight_date)}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="text-gray-900 dark:text-gray-100">
                                                    {flight.departure_airfield} {flight.departure_time}
                                                </div>
                                                <div className="mt-1 text-gray-900 dark:text-gray-100">
                                                    {flight.arrival_airfield} {flight.arrival_time}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="text-gray-900 dark:text-gray-100">
                                                    {flight.flight_time} / {flight.ground_time}
                                                </div>
                                                <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                    Landings {flight.number_of_landings}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="text-gray-900 dark:text-gray-100">
                                                    {flight.engine_time_before_flight} - {flight.engine_time_after_flight}
                                                </div>
                                                <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                    Used {flight.engine_time_difference}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="text-gray-900 dark:text-gray-100">
                                                    {flight.fuel_on_inspection} + {flight.fuel_added}; TO: {flight.fuel_on_departure}
                                                </div>
                                                <div className="mt-1 text-gray-500 dark:text-gray-300">
                                                    Landing {flight.fuel_on_landing}; Used {flight.fuel_used}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div className="text-gray-900 dark:text-gray-100">{flight.remarks}</div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default UserFlightsTable;
