import React, { createContext, ReactNode } from 'react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';

// Define the props interface
interface AppThemeProviderProps {
    children: ReactNode;
}

export const AppThemeProvider: React.FC<AppThemeProviderProps> = ({ children }) => {
    return (
        <NextThemesProvider
            attribute="class"          // Ensures the 'dark' class is added to the root element
            defaultTheme="system"      // Sets the default theme to system preference
            enableSystem={true}        // Enables system theme detection
            disableTransitionOnChange // Prevents unwanted transitions on theme change
        >
            {children}
        </NextThemesProvider>
    );
};
