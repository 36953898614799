'use client';

import React, { useEffect, useState } from 'react'
import { useTheme } from 'next-themes'
import { SunIcon, MoonIcon } from '@heroicons/react/24/solid'

const ThemeToggleButton: React.FC = () => {
    const { setTheme, resolvedTheme } = useTheme();
    const [mounted, setMounted] = useState(false);

    // Ensures that theme is mounted to avoid hydration mismatch
    useEffect(() => {
        setMounted(true)
    }, []);

    if (!mounted) return null

    const toggleTheme = () => {
        const newTheme = resolvedTheme === 'dark' ? 'light' : 'dark'
        setTheme(newTheme)
    }

    return (
        <div
            onClick={toggleTheme}
            aria-label={`Switch to ${resolvedTheme === 'dark' ? 'light' : 'dark'} mode`}
            className="group rounded-full bg-white/90 px-3 py-1 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
        >
            {resolvedTheme === 'dark' ? (
                <MoonIcon className="h-5 w-5 text-gray-300" />
            ) : (
                <SunIcon className="h-5 w-5 text-yellow-500" />
            )}
        </div>
    )
}

export default ThemeToggleButton
