// src/components/Forms/MaintenanceStatementForm.tsx

import React, { useState } from 'react';
import { Aircraft, MaintenanceStatement } from '../../../types';
import { Input } from '../../../catalystui/input';
import { Textarea } from '../../../catalystui/textarea';
import { Button } from '../../../catalystui/button';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../../../catalystui/fieldset';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../catalystui/dialog';
import { Badge } from '../../../catalystui/badge';
import { v4 as uuidv4 } from 'uuid';
import {XCircleIcon} from "@heroicons/react/20/solid";


interface MaintenanceStatementFormProps {
    onSubmit: (data: MaintenanceStatement) => void;
    aircraft: Aircraft;
    isOpen: boolean;
    onClose: () => void;
}

interface MaintenanceStatementFormProps {
    onSubmit: (data: MaintenanceStatement) => void;
    aircraft: Aircraft;
    isOpen: boolean;
    onClose: () => void;
}

type FieldEntry = {
    field: keyof MaintenanceStatement;
    label: string;
};

const MaintenanceStatementForm: React.FC<MaintenanceStatementFormProps> = ({
                                                                               onSubmit,
                                                                               aircraft,
                                                                               isOpen,
                                                                               onClose,
                                                                           }) => {
    const [error, setError] = useState<string | null>(null);
    const [statementData, setStatementData] = useState<MaintenanceStatement>({
        uuid: '',
        documentType: 'Maintenance Statement',
        documentNumber: '',
        crsNumber: '',
        returnToServiceDate: '',
        validTillMTH: 0,
        validTillMTHTolerance: 0,
        validTillDate: '',
        validTillDateDaysTolerance: 0,
        nextMaintenanceLevelByHours: '',
        nextMaintenanceLevelByCalendar: '',
        remarks: '',
    });

    const handleChange = (field: keyof MaintenanceStatement, value: any) => {
        setStatementData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const validateStatementData = (): boolean => {
        // Clear previous errors
        setError(null);

        // Required fields
        const requiredFields: FieldEntry[] = [
            { field: 'documentNumber', label: 'Maintenance Statement Number' },
            { field: 'crsNumber', label: 'CRS Number' },
            { field: 'returnToServiceDate', label: 'Return to Service Date' },
            { field: 'validTillMTH', label: 'Valid Till MTH' },
            { field: 'validTillDate', label: 'Valid Till Date' },
        ];

        for (let i = 0; i < requiredFields.length; i++) {
            const { field, label } = requiredFields[i];
            if (!statementData[field]) {
                setError(`${label} is required.`);
                return false;
            }
        }

        // Validate numeric fields
        const numericFields: FieldEntry[] = [
            { field: 'validTillMTH', label: 'Valid Till MTH' },
            { field: 'validTillMTHTolerance', label: '± MTH Tolerance' },
            { field: 'validTillDateDaysTolerance', label: '± Days Tolerance' },
        ];

        for (let i = 0; i < numericFields.length; i++) {
            const { field, label } = numericFields[i];
            if (
                statementData[field] === null ||
                isNaN(Number(statementData[field]))
            ) {
                setError(`${label} must be a valid number.`);
                return false;
            }
        }

        // Validate date fields
        const dateFields: FieldEntry[] = [
            { field: 'returnToServiceDate', label: 'Return to Service Date' },
            { field: 'validTillDate', label: 'Valid Till Date' },
        ];

        for (let i = 0; i < dateFields.length; i++) {
            const { field, label } = dateFields[i];
            if (statementData[field]) {
                const date = new Date(statementData[field] as string);
                if (isNaN(date.getTime())) {
                    setError(`${label} must be a valid date.`);
                    return false;
                }
            } else {
                setError(`${label} is required.`);
                return false;
            }
        }

        // All validations passed
        return true;
    };

    const handleSave = () => {
        if (!validateStatementData()) {
            // Validation failed; error message is set in validateStatementData
            return;
        }
        if (!statementData.uuid) {
            statementData.uuid = uuidv4();
        }
        // Clear any previous error
        setError(null);
        onSubmit(statementData);
    };

    return (
        <Dialog size="5xl" open={isOpen} onClose={onClose}>
            <DialogTitle>Add Maintenance Statement</DialogTitle>
            <DialogDescription>
                Fill out the details for the Maintenance Statement to add it to the aircraft’s record.
            </DialogDescription>
            {/* Display the error message */}
            {error && (
                <div className="rounded-md my-2 bg-red-50 dark:bg-red-800 p-2">
                    <div className="flex">
                        <div className="shrink-0">
                            <XCircleIcon aria-hidden="true" className="size-5 text-red-400 dark:text-red-100"/>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-100">{error}</h3>
                        </div>
                    </div>
                </div>
            )}
            <DialogBody>
                <Fieldset>
                    <FieldGroup>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Field>
                                <Label>MS Number</Label>
                                <Input
                                    name="documentNumber"
                                    value={statementData.documentNumber}
                                    onChange={(e) => handleChange('documentNumber', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>CRS Number</Label>
                                <Input
                                    name="crsNumber"
                                    value={statementData.crsNumber}
                                    onChange={(e) => handleChange('crsNumber', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Return to Service Date</Label>
                                <Input
                                    type="date"
                                    name="returnToServiceDate"
                                    value={statementData.returnToServiceDate}
                                    onChange={(e) => handleChange('returnToServiceDate', e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Field>
                                <Label>Valid Till MTH</Label>
                                <Input
                                    type="number"
                                    name="validTillMTH"
                                    value={statementData.validTillMTH}
                                    onChange={(e) => handleChange('validTillMTH', parseFloat(e.target.value))}
                                />
                            </Field>
                            <Field>
                                <Label>± MTH Tolerance</Label>
                                <Input
                                    type="number"
                                    name="validTillMTHTolerance"
                                    value={statementData.validTillMTHTolerance}
                                    onChange={(e) => handleChange('validTillMTHTolerance', parseFloat(e.target.value))}
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <Field>
                                <Label>Valid Till Date</Label>
                                <Input
                                    type="date"
                                    name="validTillDate"
                                    value={statementData.validTillDate}
                                    onChange={(e) => handleChange('validTillDate', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>± Days Tolerance</Label>
                                <Input
                                    type="number"
                                    name="validTillDateDaysTolerance"
                                    value={statementData.validTillDateDaysTolerance}
                                    onChange={(e) => handleChange('validTillDateDaysTolerance', parseInt(e.target.value))}
                                />
                            </Field>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Field>
                                <Label>Next Maintenance Level By Hours</Label>
                                <Input
                                    name="nextMaintenanceLevelByHours"
                                    value={statementData.nextMaintenanceLevelByHours}
                                    onChange={(e) => handleChange('nextMaintenanceLevelByHours', e.target.value)}
                                />
                            </Field>
                            <Field>
                                <Label>Next Maintenance Level By Calendar</Label>
                                <Input
                                    name="nextMaintenanceLevelByCalendar"
                                    value={statementData.nextMaintenanceLevelByCalendar}
                                    onChange={(e) => handleChange('nextMaintenanceLevelByCalendar', e.target.value)}
                                />
                            </Field>
                        </div>
                        <Field>
                            <Label>Remarks</Label>
                            <Textarea
                                name="remarks"
                                value={statementData.remarks}
                                onChange={(e) => handleChange('remarks', e.target.value)}
                            />
                        </Field>
                    </FieldGroup>
                </Fieldset>
            </DialogBody>
            <DialogActions>
                <Button plain onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MaintenanceStatementForm;
