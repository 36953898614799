import React, {useEffect, useState} from 'react';
import apiClient from '../../../tools/AxiosInterceptor';
import RankingsVisualization from './RankingsVisualization';
import {Flight} from "../../../types";

const RankingsWrapper = () => {
    const [allUserMTH, setAllUserMTH] = useState<{ uuid: string; name: string; mth: number }[]>([]);
    const [allUserFlightTime, setAllUserFlightTime] = useState<{
        uuid: string;
        name: string;
        flightTime: number
    }[]>([]);
    const [user, setUser] = useState<{ uuid: string } | null>(null);

    useEffect(() => {
        const fetchRankingsData = async () => {
            try {
                const userUuid = sessionStorage.getItem('user_uuid'); // Ensure user_uuid is retrieved securely
                const token = sessionStorage.getItem('token'); // Retrieve the token for authorization

                if (!userUuid || !token) {
                    console.error("User UUID or token not found in sessionStorage");
                    return;
                }

                const [flightsResponse, usersResponse] = await Promise.all([
                    apiClient.get<Flight[]>('/getFlights', {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }),
                    apiClient.get<{ users: { uuid: string; first_name: string; last_name: string }[] }>('/getUsers', {
                        headers: { 'Authorization': `Bearer ${token}` }
                    })
                ]);

                const userMap = new Map(
                    usersResponse.data.users.map((u) => [u.uuid, `${u.first_name} ${u.last_name}`])
                );

                const allFlights = flightsResponse.data;

                // Calculate MTH Leaderboard
                const mthLeaderboard = Object.entries(
                    allFlights.reduce((acc: { [key: string]: number }, flight) => {
                        if (!flight.pic_user_uuid || isNaN(flight.engine_time_difference)) {
                            console.warn("Invalid flight data:", flight);
                            return acc;
                        }
                        acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + flight.engine_time_difference;
                        return acc;
                    }, {})
                )
                    .map(([uuid, mth]) => ({
                        uuid,
                        mth: parseFloat(mth.toFixed(1)), // Round to 1 decimal for consistency
                        name: userMap.get(uuid) || 'Unknown',
                    }))
                    .sort((a, b) => b.mth - a.mth); // Sort by MTH descending


                // Calculate Flight Time Leaderboard
                const flightTimeLeaderboard = Object.entries(
                    allFlights.reduce((acc: { [key: string]: number }, flight) => {
                        if (!flight.pic_user_uuid || !flight.flight_time) {
                            console.warn("Invalid flight data:", flight);
                            return acc;
                        }
                        const flightTime = parseFlightTimeToMinutes(flight.flight_time);
                        acc[flight.pic_user_uuid] = (acc[flight.pic_user_uuid] || 0) + flightTime;
                        return acc;
                    }, {})
                )
                    .map(([uuid, flightTime]) => ({
                        uuid,
                        flightTime: parseFloat((flightTime / 60).toFixed(2)), // Convert to hours with 2 decimals
                        name: userMap.get(uuid) || 'Unknown',
                    }))
                    .sort((a, b) => b.flightTime - a.flightTime); // Sort by flight time descending

                setAllUserMTH(mthLeaderboard);
                setAllUserFlightTime(flightTimeLeaderboard);

                setUser({ uuid: userUuid });

            } catch (error) {
                console.error("Error fetching rankings data:", error);
            }
        };

        fetchRankingsData();
    }, []);

    const parseFlightTimeToMinutes = (timeString: string): number => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };

    return (
        <div className="mb-20">
            <RankingsVisualization user={user} allUserMTH={allUserMTH} allUserFlightTime={allUserFlightTime}/>
        </div>
    );
};

export default RankingsWrapper;
