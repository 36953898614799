import React from 'react';
import {Badge} from '../../../catalystui/badge';
import {Heading} from "../../../catalystui/heading"; // Update this path to where you save your Tooltip component

interface RankingsVisualizationProps {
    user: { uuid: string } | null; // Minimal user info for matching
    allUserMTH: { uuid: string; name: string; mth: number }[];
    allUserFlightTime: { uuid: string; name: string; flightTime: number }[];
}

const RankingsVisualization: React.FC<RankingsVisualizationProps> = ({user, allUserMTH, allUserFlightTime}) => {
    const formatTimeInHHMM = (totalTime: number): string => {
        const hours = Math.floor(totalTime);
        const minutes = Math.round((totalTime - hours) * 100);
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
    };
    const getRankColor = (rank: number): string => {
        if (rank === 1) return 'bg-gradient-to-r from-yellow-300 to-white text-black dark:from-yellow-800 dark:to-zinc-900 dark:text-white';
        if (rank === 2) return 'bg-gradient-to-r from-gray-200 to-white text-black dark:from-zinc-600 dark:to-zinc-900 dark:text-white';
        if (rank === 3) return 'bg-gradient-to-r from-orange-300 to-white text-black dark:from-orange-800 dark:to-zinc-900 dark:text-white';
        return 'bg-white dark:bg-zinc-900';
    };

    const isCurrentUser = (uuid: string): boolean => user?.uuid === uuid;

    return (
        <div className="grid gap-8 sm:grid-cols-1 lg:grid-cols-2">
            {/* MTH Leaderboard */}
            <div>
                <Heading>MTH Leaderboard</Heading>
                <ul className="mt-4 divide-y divide-gray-200 dark:divide-gray-700 rounded-lg overflow-hidden">
                    {allUserMTH.slice(0, 5).map((entry, index) => (
                        <li
                            key={entry.uuid}
                            className={`flex justify-between items-center p-3 shadow-md hover:shadow-lg transition-all ${getRankColor(index + 1)} dark:bg-gray-700`}
                        >
                            <div className="flex items-center gap-4">
                                <span className="text-lg font-bold dark:text-white">{index + 1}</span>
                                <span
                                    className={`text-sm ${isCurrentUser(entry.uuid) ? 'font-bold text-gray-800 dark:text-gray-200' : 'font-medium text-gray-800 dark:text-gray-200'}`}
                                >
                    {entry.name}
                </span>
                            </div>
                            <Badge color="lime">{entry.mth.toFixed(1)} MTH</Badge>
                        </li>
                    ))}
                </ul>

            </div>

            {/* Flight Time Leaderboard */}
            <div>
                <Heading>Flight Time Leaderboard</Heading>
                <ul className="mt-4 divide-y divide-gray-200 dark:divide-gray-700 rounded-lg overflow-hidden">
                    {allUserFlightTime.slice(0, 5).map((entry, index) => (
                        <li
                            key={entry.uuid}
                            className={`flex justify-between items-center p-3 shadow-md hover:shadow-lg transition-all ${getRankColor(index + 1)} dark:bg-gray-700`}
                        >
                            <div className="flex items-center gap-4">
                                <span className="text-lg font-bold dark:text-white">{index + 1}</span>
                                <span
                                    className={`text-sm ${isCurrentUser(entry.uuid) ? 'font-bold text-gray-800 dark:text-gray-200' : 'font-medium text-gray-800 dark:text-gray-200'}`}
                                >
                                    {entry.name}
                                </span>
                            </div>
                            <Badge color="lime">{formatTimeInHHMM(entry.flightTime)}</Badge>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RankingsVisualization;
