import React, {useState} from 'react';
import {useUsers} from '../../../hooks/useUsers';
import UserForm from './UserForm';
import {Heading} from '../../../catalystui/heading';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from '../../../catalystui/table';
import {Avatar} from '../../../catalystui/avatar';
import {Badge} from '../../../catalystui/badge';
import md5 from 'md5';
import {Input} from '../../../catalystui/input';
import {Button} from '../../../catalystui/button';
import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from '../../../catalystui/dropdown';
import {EllipsisHorizontalIcon} from '@heroicons/react/20/solid';
import {User} from '../../../types';
import {Text} from '../../../catalystui/text';
import {useNavigate} from "react-router-dom";
import RankingsWrapper from "./RankingsWrapper";

const UserListSection: React.FC = () => {
    const {users, error, loading, addUser, updateUser} = useUsers();
    const [searchTerm, setSearchTerm] = useState('');
    const [formVisible, setFormVisible] = useState(false);
    const [editingUser, setEditingUser] = useState<User | undefined>(undefined);
    const [formError, setFormError] = useState<string>('')

    const currentUserRole = sessionStorage.getItem('user_role'); // Assuming the role is stored in session storage

    // Remove duplicates based on 'uuid'
    const uniqueUsers = Array.from(new Map(users.map((user) => [user.uuid, user])).values());

    // Filter users
    const filteredUsers = uniqueUsers.filter((user) => {
        const search = searchTerm.toLowerCase();
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        const email = user.email.toLowerCase();
        const license = user.pilot?.pilot_license_number.toLowerCase() ?? '';
        const selectedOrg = sessionStorage.getItem('selectedOrg');
        const currentOrganizationId = selectedOrg ? JSON.parse(selectedOrg)?.id : null;

        const matchesSearch =
            fullName.includes(search) || email.includes(search) || license.includes(search);

        const matchesOrg =
            user.organizationIds.includes(currentOrganizationId);

        return matchesSearch && matchesOrg;
    });

    const getGravatarUrl = (email: string) => {
        const hash = md5(email.trim().toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=mp`;
    };


    // Helper function to calculate days till expiry
    const getDaysTillExpiry = (dateString: string | undefined): number => {
        if (!dateString) {
            return 0; // Default value for missing or invalid dates
        }

        const expiryDate = new Date(dateString);

        if (isNaN(expiryDate.getTime())) {
            return 0; // Default value for invalid dates
        }

        const today = new Date();
        const timeDiff = expiryDate.getTime() - today.getTime();
        const daysTillExpiry = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

        return daysTillExpiry;
    };


    // Define the BadgeColor type
    type BadgeColor =
        | 'green'
        | 'orange'
        | 'rose'
        | 'zinc'
        | 'indigo'
        | 'cyan'
        | 'red'
        | 'amber'
        | 'yellow'
        | 'lime'
        | 'emerald'
        | 'teal'
        | 'sky'
        | 'blue'
        | 'violet'
        | 'purple'
        | 'fuchsia'
        | 'pink';

    // Helper function to get color based on days till expiry
    const getExpiryColor = (days: number): BadgeColor => {
        if (days > 90) return 'green';
        else if (days > 30) return 'orange';
        else return 'rose';
    };

    const handleAddUser = () => {
        setFormError('');
        setEditingUser(undefined); // Add mode
        setFormVisible(true);
    };

    const handleEditUser = (user: User) => {
        setFormError('');
        setEditingUser(user); // Edit mode
        setFormVisible(true);
    };

    const handleUserSubmit = async (formValues: Partial<User>) => {
        try {
            if (editingUser) {
                await updateUser(editingUser.uuid, formValues);
            } else {
                await addUser(formValues);
            }
            setFormError(''); // Clear any existing errors
            setFormVisible(false); // Close form only if successful
        } catch (err: any) {
            // Pass the error message back to the form for display
            const errorMessage = err.response?.data?.message || "An unexpected error occurred.";
            setFormError(errorMessage); // Assume `setFormError` updates the form's local error state
        }
    };

    const handleFormCancel = () => {
        setFormError('');
        setFormVisible(false);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center py-10">
                <span className="text-gray-500">Loading users...</span>
            </div>
        );
    }

    return (
        <main>
            <RankingsWrapper/>
            <div>
                {/* Header */}
                <div className="lg:flex pb-6 lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <Heading>Users</Heading>
                    </div>
                    <div className="mt-5 flex lg:ml-4 lg:mt-0 pl-3">
                        <span className="sm:block sm:ml-3">
                            <Input
                                placeholder="Search users..."
                                onChange={(e) => setSearchTerm(e.target.value)}
                                value={searchTerm}
                            />
                        </span>

                        {currentUserRole === 'admin' && (
                            <span className="ml-3">
                                <Button onClick={handleAddUser}>Add User</Button>
                            </span>
                        )}
                    </div>
                </div>

                {/* Table */}
                <Table dense className="[--gutter:theme(spacing.4)] sm:[--gutter:theme(spacing.6)]">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Medical Expiry</TableHeader>
                            <TableHeader>Ratings</TableHeader>
                            <TableHeader></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <TableRow key={user.uuid} href={`/dashboard/user/${user.uuid}`}>
                                <TableCell>
                                    <div className="flex items-center gap-4">
                                        <Avatar src={getGravatarUrl(user.email)} className="size-12"/>
                                        <div>
                                            <div className="font-medium">
                                                {user.first_name} {user.last_name}
                                            </div>
                                            <div className="text-zinc-500">{user.pilot?.pilot_license_number}</div>
                                            {user.role === 'admin' ? (
                                                <Badge color="green">{user.role}</Badge>
                                            ) : (
                                                <Badge color="zinc">{user.role}</Badge>
                                            )}
                                        </div>
                                    </div>
                                </TableCell>
                                {/* Medical Expiry with Days Till Expiry */}
                                <TableCell className="text-xs">
                                    {user.pilot?.medical?.validTill ? (
                                        <div className="flex items-center gap-2">
                                            {new Date(user.pilot.medical.validTill).toLocaleDateString()}
                                            <Badge
                                                color={getExpiryColor(getDaysTillExpiry(user.pilot.medical.validTill) || 0)} // Fallback to 0 for invalid dates
                                            >
                                                {getDaysTillExpiry(user.pilot.medical.validTill) !== null
                                                    ? `${getDaysTillExpiry(user.pilot.medical.validTill)} days`
                                                    : 'N/A'}
                                            </Badge>
                                        </div>
                                    ) : (
                                        'N/A'
                                    )}
                                </TableCell>

                                {/* Ratings */}
                                <TableCell className="text-xs">
                                    {user.pilot?.ratings && user.pilot.ratings.length > 0 ? (
                                        user.pilot.ratings.map((rating, index) => (
                                            <div key={index} className="mb-2 flex items-center gap-2">
                                                {rating.ratingName} (Expiry: {new Date(rating.validTill).toLocaleDateString()})
                                                <Badge
                                                    color={getExpiryColor(getDaysTillExpiry(rating.validTill))}
                                                >
                                                    {getDaysTillExpiry(rating.validTill)} days
                                                </Badge>
                                            </div>
                                        ))
                                    ) : (
                                        'N/A'
                                    )}
                                </TableCell>
                                <TableCell>
                                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                                        <Dropdown>
                                            <DropdownButton plain aria-label="More options">
                                                <EllipsisHorizontalIcon/>
                                            </DropdownButton>
                                            <DropdownMenu anchor="bottom end">
                                                <DropdownItem>
                                                    <a
                                                        href={`tel:${user.phoneNumber}`}
                                                        className="text-gray-700 hover:text-indigo-600"
                                                    >
                                                        Call
                                                    </a>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <a
                                                        href={`mailto:${user.email}`}
                                                        className="text-gray-700 hover:text-indigo-600"
                                                    >
                                                        Email
                                                    </a>
                                                </DropdownItem>
                                                {currentUserRole === 'admin' && (
                                                    <DropdownItem onClick={() => handleEditUser(user)}>
                                                        Edit
                                                    </DropdownItem>
                                                )}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            {/* User Form Modal */}
            {formVisible && (
                <UserForm
                    isOpen={formVisible}
                    userData={editingUser}
                    onSubmit={handleUserSubmit}
                    onClose={handleFormCancel}
                    errorMessage={formError}
                />
            )}
        </main>
    );
};

export default UserListSection;
