import { useState, useEffect } from 'react';
import apiClient from '../tools/AxiosInterceptor';
import { User } from '../types';

export const useUsers = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    // Fetch all users
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await apiClient.get('/getUsers', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                });
                setUsers(response.data.users);
            } catch (err: any) {
                setError(err.message || 'Unable to fetch users.');
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    // Add a new user
    const addUser = async (newUser: Partial<User>) => {
        const response = await apiClient.post('/addUser', newUser, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
        setUsers((prevUsers) => [...prevUsers, response.data]); // Add new user to the state
    };

    // Update an existing user
    const updateUser = async (uuid: string, updatedFields: Partial<User>) => {
        const response = await apiClient.put(`/updateUser/${uuid}`, updatedFields, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
        setUsers((prevUsers) =>
            prevUsers.map((user) => (user.uuid === uuid ? response.data : user))
        );
    };

    return { users, error, loading, addUser, updateUser };
};
