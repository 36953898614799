import React from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { User, Aircraft, Flight } from '../../../types';
import { classNames } from '../../../tools/utils';
import { Subheading } from '../../../catalystui/heading';
import { Text } from '../../../catalystui/text';

interface GeneralFlightInfoSectionProps {
    formValues: Flight;
    pilots: User[];
    aircrafts: Aircraft[];
    selectedPilot: User | null;
    selectedAircraft: Aircraft | null;
    setSelectedPilot: (pilot: User) => void;
    setSelectedAircraft: (aircraft: Aircraft) => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    pilotQuery: string;
    setPilotQuery: (query: string) => void;
    aircraftQuery: string;
    setAircraftQuery: (query: string) => void;
}
const GeneralFlightInfoSection: React.FC<GeneralFlightInfoSectionProps> = ({
                                                                               formValues,
                                                                               pilots,
                                                                               aircrafts,
                                                                               selectedPilot,
                                                                               selectedAircraft,
                                                                               setSelectedPilot,
                                                                               setSelectedAircraft,
                                                                               handleChange,
                                                                               pilotQuery,
                                                                               setPilotQuery,
                                                                               aircraftQuery,
                                                                               setAircraftQuery,
                                                                           }) => {
    // Filtered pilots and aircrafts based on the query
    const filteredPilots = pilotQuery === ''
        ? pilots
        : pilots.filter((pilot) => {
            return (
                pilot.first_name.toLowerCase().includes(pilotQuery.toLowerCase()) ||
                pilot.last_name.toLowerCase().includes(pilotQuery.toLowerCase())
            );
        });

    const filteredAircrafts = aircraftQuery === ''
        ? aircrafts
        : aircrafts.filter((aircraft) =>
            aircraft.registration.toLowerCase().includes(aircraftQuery.toLowerCase())
        );

    return (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-4">
            <div className="px-4 sm:px-0">
                <Subheading>General</Subheading>
                <Text>
                    Aircraft, Pilot in Command, Route
                </Text>
                <Text>
                    {formValues.organizationId}
                </Text>
            </div>
            <div
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                <div className="px-4 py-4 sm:px-8 sm:py-8">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4">
                        <div className="sm:col-span-2">
                            {/* Pilot Combobox */}
                            <Combobox as="div" value={selectedPilot} onChange={setSelectedPilot}>
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                    Pilot in Command
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900
        shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset
        focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                        onChange={(event) => setPilotQuery(event.target.value)}
                                        displayValue={(pilot: User | null) => (pilot ? `${pilot.first_name} ${pilot.last_name}` : '')}
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </Combobox.Button>

                                    {filteredPilots.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
          py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
          dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                        >
                                            {filteredPilots.map((pilot) => (
                                                <Combobox.Option
                                                    key={pilot.uuid}
                                                    value={pilot}
                                                    className={({ active }) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-8 pr-4',
                                                            active
                                                                ? 'bg-indigo-600 text-white dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600'
                                                                : 'text-gray-900 dark:text-gray-100'
                                                        )
                                                    }
                                                >
                                                    {({ active, selected }) => (
                                                        <>
                <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                  {pilot.first_name} {pilot.last_name}
                </span>
                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                        active ? 'text-white' : 'text-indigo-600 dark:text-gray-100'
                                                                    )}
                                                                >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="first-name"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                License Number
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="license_number"
                                    id="license_number"
                                    autoComplete="license_number"
                                    defaultValue={selectedPilot?.pilot?.pilot_license_number || ''}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <Combobox as="div" value={selectedAircraft} onChange={setSelectedAircraft}>
                                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                    Aircraft Registration
                                </Combobox.Label>
                                <div className="relative mt-2">
                                    <Combobox.Input
                                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900
        shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset
        focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                        onChange={(event) => setAircraftQuery(event.target.value)}
                                        displayValue={(aircraft: Aircraft | null) => (aircraft ? aircraft.registration : '')}
                                        placeholder="Select or search aircraft registration..."
                                    />
                                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </Combobox.Button>

                                    {filteredAircrafts.length > 0 && (
                                        <Combobox.Options
                                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white
          py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
          dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600"
                                        >
                                            {filteredAircrafts.map((aircraft) => (
                                                <Combobox.Option
                                                    key={aircraft.uuid}
                                                    value={aircraft}
                                                    className={({ active }) =>
                                                        classNames(
                                                            'relative cursor-default select-none py-2 pl-8 pr-4',
                                                            active
                                                                ? 'bg-indigo-600 text-white dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600'
                                                                : 'text-gray-900 dark:text-gray-100'
                                                        )
                                                    }
                                                >
                                                    {({ active, selected }) => (
                                                        <>
                <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>
                  {aircraft.registration}
                </span>
                                                            {selected && (
                                                                <span
                                                                    className={classNames(
                                                                        'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                        active ? 'text-white' : 'text-indigo-600 dark:text-gray-100'
                                                                    )}
                                                                >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                                                            )}
                                                        </>
                                                    )}
                                                </Combobox.Option>
                                            ))}
                                        </Combobox.Options>
                                    )}
                                </div>
                            </Combobox>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="flight_date"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Date
                            </label>
                            <div className="mt-2">
                                <input
                                    type="date"
                                    name="flight_date"
                                    id="flight_date"
                                    autoComplete="flight_date"
                                    defaultValue={formValues?.flight_date || ''}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                    placeholder="Select Flight Date"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="departure_airfield"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Departure
                            </label>
                            <div className="mt-2 flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                    <input
                                        type="text"
                                        name="departure_airfield"
                                        id="departure_airfield"
                                        autoComplete="departure_airfield"
                                        value={formValues.departure_airfield}
                                        onChange={handleChange}
                                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900
                           ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                        placeholder="ICAO Airport Code"
                                    />
                                </div>
                                <input
                                    type="time"
                                    name="departure_time"
                                    id="departure_time"
                                    autoComplete="departure_time"
                                    value={formValues.departure_time}
                                    onChange={handleChange}
                                    className="relative -ml-px flex-shrink-0 inline-flex items-center border-0
                       rounded-r-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300
                       hover:bg-gray-50
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:hover:bg-zinc-700"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="arrival_airfield"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Arrival
                            </label>
                            <div className="mt-2 flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                    <input
                                        type="text"
                                        name="arrival_airfield"
                                        id="arrival_airfield"
                                        autoComplete="arrival_airfield"
                                        value={formValues.arrival_airfield}
                                        onChange={handleChange}
                                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900
                           ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                        placeholder="ICAO Airport Code"
                                    />
                                </div>
                                <input
                                    type="time"
                                    name="arrival_time"
                                    id="arrival_time"
                                    autoComplete="arrival_time"
                                    value={formValues.arrival_time}
                                    onChange={handleChange}
                                    className="relative -ml-px flex-shrink-0 inline-flex items-center border-0
                       rounded-r-md px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300
                       hover:bg-gray-50
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:hover:bg-zinc-700"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="ground_time"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Ground Time (min)
                            </label>
                            <div className="mt-2">
                                <input
                                    type="time"
                                    name="ground_time"
                                    id="ground_time"
                                    autoComplete="ground_time"
                                    value={formValues.ground_time}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                    placeholder="Select Ground Time"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="flight_time"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Flight Time
                            </label>
                            <div className="mt-2">
                                <input
                                    type="time"
                                    name="flight_time"
                                    id="flight_time"
                                    autoComplete="flight_time"
                                    value={formValues.flight_time}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                    placeholder="Select Flight Time"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="number_of_landings"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Number of Landings
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="number_of_landings"
                                    id="number_of_landings"
                                    autoComplete="number_of_landings"
                                    value={formValues.number_of_landings}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                       shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                       focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                       dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                       dark:focus:ring-indigo-500"
                                    placeholder="Enter Number of Landings"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralFlightInfoSection;
