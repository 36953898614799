import React, { useState } from 'react';
import { Badge } from '../../../catalystui/badge';
import OEKMT from '../../../media/OE_KMT_mountains.jpeg';
import ECFPQ from '../../../media/Cessna-150-MRO-Aviation-Services.jpg';

interface Aircraft {
    uuid: string;
    registration: string;
    manufacturer: string;
    model: string;
}

interface ExpiringDocument {
    registration: string;
    documentType?: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
    scheduleDescription?: string;
    isOverdue?: boolean;
}

interface UpcomingActivity {
    componentUuid: string;
    scheduleDescription: string;
    nextDueDate?: string;
    nextDueHours?: number;
    status: string;
    details: string;
}

interface AircraftItemProps {
    aircraft: Aircraft;
    expiringDocuments: ExpiringDocument[];
    upcomingActivities: UpcomingActivity[];
}

const AircraftItem: React.FC<AircraftItemProps> = ({ aircraft, expiringDocuments, upcomingActivities }) => {
    const { registration, manufacturer, model, uuid } = aircraft;
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => setExpanded((prev) => !prev);

    // Determine badge color based on whether the item is upcoming or overdue
    const getBadgeColor = (isOverdue: boolean) => (isOverdue ? 'red' : 'orange');

    // Map registration to corresponding images
    const aircraftImages = {
        "OE-KMT": OEKMT,
        "EC-FPQ": ECFPQ,
    };

    // Fallback to a default image if registration is not mapped
    const defaultAircraftImage = '../../../media/OE_KMT_mountains.jpeg'; // Replace with your default image path
    const imageSrc = aircraftImages[registration as keyof typeof aircraftImages] || defaultAircraftImage;


    return (
        <li>
            <hr role="presentation" className="w-full border-t border-zinc-950/10 dark:border-white/10" />
            <div className="flex items-center justify-between">
                <div className="flex gap-6 py-6">
                    <div className="w-32 shrink-0">
                        <a href={`/dashboard/aircraft/${uuid}`} aria-hidden="true">
                            <img
                                className="aspect-[3/2] rounded-lg shadow"
                                src={imageSrc}
                                alt={`${registration} aircraft`}
                            />
                        </a>
                    </div>
                    <div className="space-y-1.5">
                        <div className="text-base font-semibold text-gray-900 dark:text-gray-100">
                            <a href={`/dashboard/aircraft/${uuid}`}>{registration}</a>
                        </div>
                        <div className="text-xs text-zinc-500 dark:text-zinc-300">
                            {manufacturer || 'Unknown Manufacturer'}
                        </div>
                        <div className="text-xs text-zinc-600 dark:text-zinc-400">
                            {model || 'Unknown Model'}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-end gap-2">
                    {expiringDocuments.length > 0 ? (
                        <>
                            {expiringDocuments.slice(0, expanded ? expiringDocuments.length : 1).map((doc, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <Badge color={getBadgeColor(!!doc.isOverdue)}>
                                        {doc.documentType
                                            ? `${doc.details}`
                                            : `${doc.scheduleDescription}: ${doc.status}`}
                                    </Badge>
                                </div>
                            ))}
                            {expiringDocuments.length > 1 && (
                                <button
                                    onClick={toggleExpanded}
                                    className="text-xs text-blue-500 mt-2 hover:underline focus:outline-none"
                                >
                                    {expanded ? 'Show Less' : `Show ${expiringDocuments.length - 1} More`}
                                </button>
                            )}
                        </>
                    ) : (
                        <Badge color="green">All Good</Badge>
                    )}
                </div>
            </div>
        </li>
    );
};

export default AircraftItem;
