// src/components/Dashboard/Aircraft/AddEditViewForms/AddEditAircraftForm.tsx

import React, {useState} from 'react';
import apiClient from "../../../../tools/AxiosInterceptor";
import {useNavigate} from 'react-router-dom';
import {Button} from "../../../../catalystui/button";
import {Input} from "../../../../catalystui/input";
import {Heading} from "../../../../catalystui/heading";
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {Aircraft} from "../../../../types";
import {v4 as uuidv4} from 'uuid';
import {ErrorMessage, Field, Label} from "../../../../catalystui/fieldset";
import {Text} from "../../../../catalystui/text";

const AddEditAircraftForm: React.FC = () => {
    const selectedOrg = sessionStorage.getItem('selectedOrg');
    const selectedOrgId = selectedOrg ? JSON.parse(selectedOrg).id : '';
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const [form, setForm] = useState<Aircraft>({
        registration: '',
        model: '',
        manufacturer: '',
        organizationId: selectedOrgId,
        mthCounter: 0,
        uuid: uuidv4()
    });

    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setForm(prev => ({
            ...prev,
            [name]: name === 'mthCounter' ? Number(value) : value
        }));

        // Clear the specific field error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({...prev, [name]: ''}));
        }
    };

    const validateForm = (): boolean => {
        const newErrors: Record<string, string> = {};

        if (!form.registration.trim()) {
            newErrors.registration = 'Registration is required.';
        }
        if (!form.model.trim()) {
            newErrors.model = 'Model is required.';
        }
        if (!form.uuid.trim()) {
            newErrors.uuid = 'UUID is required.';
        }
        if (!form.manufacturer.trim()) {
            newErrors.manufacturer = 'Manufacturer is required.';
        }
        if (form.mthCounter <= 0 || isNaN(form.mthCounter)) {
            newErrors.mthCounter = 'MTH Counter must be a positive number.';
        }
        if (!form.organizationId.trim()) {
            newErrors.organizationId = 'Organization ID is required.';
        }

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!validateForm()) {
            setLoading(false);
            return;
        }

        try {
            const token = sessionStorage.getItem('token');

            if (!token) {
                throw new Error('Authentication token not found.');
            }

            // Update the form with the organizationId
            const newAircraft = {
                ...form,
                organizationId: selectedOrgId,
            };

            console.log('Payload sent to backend:', newAircraft);

            // Make API call to add aircraft
            await apiClient.post('/v1/addAircraft', newAircraft, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Optionally, navigate back to the aircraft list or show a success message
            navigate('/dashboard/aircraft');
        } catch (err: any) {
            console.error('Error adding aircraft:', err);
            setError(err.response?.data?.message || err.message || 'Unable to add aircraft.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <Heading>Add New Aircraft</Heading>

            {error && (
                <div className="rounded-md bg-red-50 p-4 mt-4 dark:bg-red-800">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-red-400 dark:text-red-300"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
                                {error}
                            </h3>
                        </div>
                    </div>
                </div>
            )}

            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                <Field>
                    <Label htmlFor="registration">Registration</Label>
                    <Input
                        id="registration"
                        name="registration"
                        type="text"
                        value={form.registration}
                        onChange={handleChange}
                        placeholder="e.g., N12345"
                        invalid={!!errors.registration}
                    />
                    {errors.registration && <ErrorMessage>{errors.registration}</ErrorMessage>}
                </Field>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <Field>
                        <Label htmlFor="model">Model</Label>
                        <Input
                            id="model"
                            name="model"
                            type="text"
                            value={form.model}
                            onChange={handleChange}
                            placeholder="e.g., R172K"
                            invalid={!!errors.model}
                        />
                        {errors.model && <ErrorMessage>{errors.model}</ErrorMessage>}
                    </Field>
                    <Field>
                        <Label htmlFor="manufacturer">Manufacturer</Label>
                        <Input
                            id="manufacturer"
                            name="manufacturer"
                            type="text"
                            value={form.manufacturer}
                            onChange={handleChange}
                            placeholder="e.g., Cessna"
                            invalid={!!errors.manufacturer}
                        />
                        {errors.manufacturer && <ErrorMessage>{errors.manufacturer}</ErrorMessage>}
                    </Field>
                </div>


                <Field>
                    <Label htmlFor="mthCounter">MTH Counter</Label>
                    <Input
                        id="mthCounter"
                        name="mthCounter"
                        type="number"
                        value={form.mthCounter}
                        onChange={handleChange}
                        placeholder="e.g., 1000"
                        invalid={!!errors.mthCounter}
                        min={1} // Optional: enforce minimum value
                    />
                    {errors.mthCounter && <ErrorMessage>{errors.mthCounter}</ErrorMessage>}
                </Field>

                {/* Display UUID and Organization ID for reference */}
                <div>
                    <Text>UUID: {form.uuid}</Text>
                    <Text>Organization ID: {form.organizationId}</Text>
                </div>

                <div>
                    <Button type="submit" disabled={loading} className="w-full flex justify-center">
                        {loading ? 'Adding...' : 'Add Aircraft'}
                    </Button>
                </div>
            </form>
        </div>
    );

};

export default AddEditAircraftForm;
