// EngineOilFuelSection.tsx

import React from 'react';
import { Flight } from '../../../types';
import { Subheading } from '../../../catalystui/heading';
import { Text } from '../../../catalystui/text';
import {ExclamationTriangleIcon} from "@heroicons/react/20/solid";

interface EngineOilFuelSectionProps {
    formValues: Flight;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    validationError: string | null;
}

const EngineOilFuelSection: React.FC<EngineOilFuelSectionProps> = ({
                                                                       formValues,
                                                                       handleChange,
                                                                       validationError,
                                                                   }) => {
    return (
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-6 md:grid-cols-4">
            <div className="px-4 sm:px-0">
                <Subheading>Engine, Oil, Fuel
                </Subheading>
                <Text>State of engine, oil and fuel on
                    inspection, added, takeoff and landing
                </Text>
            </div>
            <div
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3 dark:bg-zinc-900 dark:ring-zinc-600">
                <div className="px-4 py-4 sm:px-8 sm:pt-8 sm:pb-4">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* MTH Before */}
                        <div className="sm:col-span-2">
                            <label htmlFor="engine_time_before_flight"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                MTH Before
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="engine_time_before_flight"
                                    id="engine_time_before_flight"
                                    autoComplete="engine_time_before_flight"
                                    step="0.1"
                                    value={formValues.engine_time_before_flight}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                    placeholder="Enter MTH Before"
                                />
                            </div>
                        </div>

                        {/* MTH After */}
                        <div className="sm:col-span-2">
                            <label htmlFor="engine_time_after_flight"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                MTH After
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="engine_time_after_flight"
                                    id="engine_time_after_flight"
                                    autoComplete="engine_time_after_flight"
                                    step="0.1"
                                    value={formValues.engine_time_after_flight}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                    placeholder="Enter MTH After"
                                />
                                {validationError && (
                                    <div className="rounded-md bg-red-50 p-4 mt-4 dark:bg-red-800">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationTriangleIcon aria-hidden="true"
                                                                         className="h-5 w-5 text-red-400 dark:text-red-300"/>
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-red-800 dark:text-red-200">{validationError}</h3>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* MTH Difference */}
                        <div className="sm:col-span-2">
                            <label htmlFor="engine_time_difference"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                MTH Difference
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="engine_time_difference"
                                    id="engine_time_difference"
                                    autoComplete="engine_time_difference"
                                    step="0.1"
                                    disabled
                                    value={formValues.engine_time_difference}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                           shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                           focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                           dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                           dark:focus:ring-indigo-500"
                                    placeholder="MTH Difference"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 py-4 sm:px-8 sm:py-4">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        {/* Oil on Inspection */}
                        <div className="sm:col-span-2">
                            <label htmlFor="oil_on_inspection"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Oil on Inspection
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="oil_on_inspection"
                                    id="oil_on_inspection"
                                    autoComplete="oil_on_inspection"
                                    step="0.1"
                                    value={formValues.oil_on_inspection}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                    placeholder="Enter Oil on Inspection"
                                />
                            </div>
                        </div>

                        {/* Oil Added */}
                        <div className="sm:col-span-2">
                            <label htmlFor="oil_added"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Oil Added
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="oil_added"
                                    id="oil_added"
                                    autoComplete="oil_added"
                                    step="0.1"
                                    value={formValues.oil_added}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                    placeholder="Enter Oil Added"
                                />
                            </div>
                        </div>

                        {/* Oil on Departure */}
                        <div className="sm:col-span-2">
                            <label htmlFor="oil_on_departure"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Oil on Departure
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="oil_on_departure"
                                    id="oil_on_departure"
                                    autoComplete="oil_on_departure"
                                    step="0.1"
                                    disabled
                                    value={formValues.oil_on_departure}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                    placeholder="Oil on Departure"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-4 py-4 sm:px-8 sm:pt-4 sm:pb-8">
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        {/* Fuel On Inspection */}
                        <div className="sm:col-span-2">
                            <label htmlFor="fuel_on_inspection"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Fuel On Inspection
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="fuel_on_inspection"
                                    id="fuel_on_inspection"
                                    autoComplete="fuel_on_inspection"
                                    value={formValues.fuel_on_inspection}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                    placeholder="Enter Fuel On Inspection"
                                />
                            </div>
                        </div>

                        {/* Fuel Added */}
                        <div className="sm:col-span-2">
                            <label htmlFor="fuel_added"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Fuel Added
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="fuel_added"
                                    id="fuel_added"
                                    autoComplete="fuel_added"
                                    value={formValues.fuel_added}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                    placeholder="Enter Fuel Added"
                                />
                            </div>
                        </div>

                        {/* Fuel on Departure */}
                        <div className="sm:col-span-2">
                            <label htmlFor="fuel_on_departure"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Fuel on Departure
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="fuel_on_departure"
                                    id="fuel_on_departure"
                                    autoComplete="fuel_on_departure"
                                    step="0.1"
                                    disabled
                                    value={formValues.fuel_on_departure}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                    placeholder="Fuel on Departure"
                                />
                            </div>
                        </div>

                        {/* Fuel on Landing */}
                        <div className="sm:col-span-2">
                            <label htmlFor="fuel_on_landing"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Fuel on Landing
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="fuel_on_landing"
                                    id="fuel_on_landing"
                                    autoComplete="fuel_on_landing"
                                    value={formValues.fuel_on_landing}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500"
                                    placeholder="Enter Fuel on Landing"
                                />
                            </div>
                        </div>

                        {/* Fuel Used */}
                        <div className="sm:col-span-2">
                            <label htmlFor="fuel_used"
                                   className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100">
                                Fuel Used
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    name="fuel_used"
                                    id="fuel_used"
                                    autoComplete="fuel_used"
                                    disabled
                                    value={formValues.fuel_used}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900
                               shadow-sm ring-1 ring-inset ring-gray-100 placeholder:text-gray-400
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                               dark:text-gray-100 dark:bg-zinc-700 dark:ring-zinc-600 dark:placeholder:text-gray-400
                               dark:focus:ring-indigo-500 cursor-not-allowed bg-gray-50 dark:bg-zinc-800"
                                    placeholder="Fuel Used"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EngineOilFuelSection;
