import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import apiClient from "../../../tools/AxiosInterceptor";
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import AircraftItem from './AircraftItem';
import {Heading} from "../../../catalystui/heading";
import {Aircraft} from "../../../types";
import {Button} from "../../../catalystui/button";
import {PlusCircleIcon} from "@heroicons/react/20/solid";

interface ExpiringDocument {
    registration: string;
    documentType: string;
    documentNumber?: string;
    expiryDate?: string;
    status: string;
    details: string;
}

interface UpcomingActivity {
    componentUuid: string;
    scheduleDescription: string;
    nextDueDate?: string;
    nextDueHours?: number;
    status: string;
    details: string;
}

const AircraftList: React.FC = () => {
    const [aircraft, setAircraft] = useState<Aircraft[]>([]);
    const [expiringDocuments, setExpiringDocuments] = useState<Record<string, ExpiringDocument[]>>({});
    const [upcomingActivities, setUpcomingActivities] = useState<Record<string, UpcomingActivity[]>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();
    const handleAddAircraft = () => {
        navigate('/dashboard/aircraft/add'); // Adjust the path as needed
    };
    const fetchAircraft = async () => {
        setLoading(true);
        setError(null);
        try {
            const token = sessionStorage.getItem('token');
            const storedOrg = sessionStorage.getItem('selectedOrg');

            if (!token) {
                throw new Error('Authentication token not found.');
            }

            if (!storedOrg) {
                throw new Error('No organization selected. Please select an organization.');
            }
            const currentOrganizationId = JSON.parse(storedOrg)?.id;

            // Fetch all aircraft
            const aircraftResponse = await apiClient.get<Aircraft[]>('/v1/getAircraft', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            console.log(aircraftResponse.data)
            // Filter aircraft by the current organization ID
            const filteredAircraft = aircraftResponse.data.filter(
                (aircraftItem) => aircraftItem.organizationId === currentOrganizationId
            );

            setAircraft(filteredAircraft);

            // Fetch expiring documents and upcoming activities for each filtered aircraft
            const expiringDocumentsPromises = filteredAircraft.map(async (aircraftItem) => {
                try {
                    const response = await apiClient.get<{
                        upcomingActivities: ExpiringDocument[],
                        upcomingActivitiesData: UpcomingActivity[]
                    }>(
                        `/v1/getExpiringDocumentsAndUpcomingActivities/${aircraftItem.uuid}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    return {
                        uuid: aircraftItem.uuid,
                        documents: response.data.upcomingActivities || [],
                        activities: response.data.upcomingActivitiesData || [],
                    };
                } catch (err) {
                    console.error(`Error fetching data for aircraft ${aircraftItem.uuid}:`, err);
                    return {uuid: aircraftItem.uuid, documents: [], activities: []};
                }
            });

            const expiringDocumentsResults = await Promise.all(expiringDocumentsPromises);

            // Organize fetched data into separate maps
            const documentsMap: Record<string, ExpiringDocument[]> = {};
            const activitiesMap: Record<string, UpcomingActivity[]> = {};

            expiringDocumentsResults.forEach((result) => {
                if (result) {
                    documentsMap[result.uuid] = result.documents;
                    activitiesMap[result.uuid] = result.activities;
                }
            });

            setExpiringDocuments(documentsMap);
            setUpcomingActivities(activitiesMap);

        } catch (err: any) {
            console.error('Error fetching aircraft:', err);
            setError(err.response?.data || err.message || 'Unable to fetch aircraft.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAircraft();
    }, []);

    return (
        <div>
            <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                    <Heading>Aircraft List</Heading>
                </div>
                {sessionStorage.getItem('user_role') == 'admin' ? (
                    <div className="mt-5 flex lg:ml-4 lg:mt-0">
                    <span className="sm:ml-3">
                            <Button onClick={handleAddAircraft}>
                                <PlusCircleIcon className="h-5 w-5 mr-2"/>
                                Add Aircraft
                            </Button>
                        </span>
                    </div>
                ) : <span/>}

            </div>

            {loading && (
                <div className="flex items-center justify-center">
                    <span className="ml-2 text-gray-700 dark:text-gray-300">
                        Loading...
                    </span>
                </div>
            )}
            {error && (
                <div className="rounded-md bg-red-50 p-4 mt-4 dark:bg-red-800">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-red-400 dark:text-red-300"
                            />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800 dark:text-red-200">
                                {error}
                            </h3>
                        </div>
                    </div>
                </div>
            )}
            {!loading && !error && aircraft.length === 0 && (
                <div className="mt-8 text-center text-zinc-500 dark:text-zinc-400">
                    No aircraft available.
                </div>
            )}
            {!loading && !error && aircraft.length > 0 && (
                <ul className="mt-10">
                    {aircraft.map((aircraftItem) => (
                        <AircraftItem
                            key={aircraftItem.uuid}
                            aircraft={aircraftItem}
                            expiringDocuments={expiringDocuments[aircraftItem.uuid] || []}
                            upcomingActivities={upcomingActivities[aircraftItem.uuid] || []}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AircraftList;

